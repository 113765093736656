define("mgw/routes/project-templates", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.findRecord("user", this.get('currentUser.user.id')),
        templates: this.store.findAll("projectTemplate"),
        libraries: this.store.query("project", {
          filter: {
            isLibrary: true
          }
        }),
        customers: this.currentUser.get('customerAdminFor')
      });
    },
    afterModel: function afterModel() {
      if (!this.currentUser.get('customerAdminFor.length')) {
        // If current user is not rendra staff and not customer admin on any customer, we cannot enter templates
        this.transitionTo('index');
      }
    },
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});