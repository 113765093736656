define("mgw/templates/components/goto-library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gdpTpo+x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"alert\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"alert__title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"libraryCreated\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"alert__description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"goToLibraryDesc\"],[[\"libraryName\"],[[35,1,[\"name\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"alert__btn\"],[4,[38,2],[[32,0],\"cancel\"],null],[12],[1,[30,[36,0],[\"cancel\"],null]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"alert__btn default\"],[4,[38,2],[[32,0],\"submit\"],null],[12],[1,[30,[36,0],[\"goToLibrary\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"data\",\"action\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/goto-library.hbs"
    }
  });

  _exports.default = _default;
});