define("mgw/components/popover-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = window.$;

  var _default = Ember.Component.extend({
    deviceservice: Ember.inject.service(),
    classNameBindings: ['hidden'],
    hideTap: false,
    fullHeight: false,
    loaded: true,
    popoverClass: null,
    sender: null,
    disabled: false,
    draggable: false,
    options: null,
    hidden: true,
    frameCache: null,
    allowShrink: false,
    observeAdjustFrame: Ember.observer('loaded', 'frameCache', function () {
      var _this = this;

      if (this.get('loaded')) {
        Ember.run.next(function () {
          _this.adjustFrame();
        });
      } else {
        this.adjustFrame();
      }
    }),
    adjustFrame: function adjustFrame() {
      var _this$get, _$, _$2, _$3, _$4;

      if (!((_this$get = this.get('popoverClass')) === null || _this$get === void 0 ? void 0 : _this$get.includes('popover--floating'))) {
        this.set('hidden', false);
        return;
      }

      if (!this.element.querySelectorAll('.popover').length) return;
      this.set('hideTap', false); // Set sender variables

      var sender = this.get('sender');
      var senderTop = 0;
      var senderHeight = 0;
      var senderLeft = 0;
      var senderWidth = 0;

      if (Ember.isArray(sender)) {
        senderLeft = sender[0];
        senderTop = sender[1] + 10;
      } else if ((_$ = $('#' + sender)) === null || _$ === void 0 ? void 0 : _$.offset()) {
        var senderElement = $('#' + sender);
        senderTop = senderElement.offset().top;
        senderHeight = senderElement.innerHeight();
        senderLeft = senderElement.offset().left;
        senderWidth = senderElement.innerWidth();
      } // Constants


      var tapWidth = 15;
      var tapHeight = 30;
      var windowWidth = this.get('deviceservice.windowWidth');
      var windowHeight = this.get('deviceservice.windowHeight'); // Popover variables

      var popoverWidth = $(this.element.querySelector('#popover')).innerWidth();
      var popoverHeight = Math.max(this.allowShrink ? 0 : $(this.element.querySelector('#popover')).innerHeight(), ($(this.element.querySelector('.scrollable-container')).innerHeight() || 0) + (((_$2 = $(this.element.querySelector('.bar--nav'))) === null || _$2 === void 0 ? void 0 : _$2.innerHeight()) || 0));
      popoverHeight = Math.min(windowHeight * 0.8, popoverHeight); // For standard class (topics and no-class)

      var popoverOffsetTop = senderTop + senderHeight / 2 - popoverHeight / 2 + tapWidth;

      if (popoverOffsetTop > 15) {
        $(this.element.querySelector('#popover')).css({
          'top': popoverOffsetTop + 'px'
        });
      }

      $(this.element.querySelector('#popover')).css({
        'min-height': popoverHeight + 'px',
        'height': popoverHeight + 'px'
      });
      var popoverTop = $(this.element.querySelector('.popover')).offset().top;
      var offsetTop = senderTop - popoverTop + senderHeight / 2 - tapHeight / 2;
      $(this.element.querySelector('#popover-tap')).css({
        top: offsetTop + 'px'
      });
      var popoverLeft = 0;
      var tapTop = 0;
      popoverTop = 0;
      var senderCenterX = senderLeft + senderWidth / 2;
      var senderCenterY = senderTop + senderHeight / 2;
      var spaceTop = senderCenterY;
      var spaceBottom = windowHeight - senderCenterY;
      var mostSpace = Math.max(spaceTop, spaceBottom);

      switch (mostSpace) {
        case spaceBottom:
          popoverLeft = Math.max(11, senderCenterX - popoverWidth / 2);
          popoverTop = senderTop + senderHeight + tapWidth - 5;
          popoverHeight = Math.min(windowHeight - senderTop - 30, popoverHeight);
          tapTop = 0;
          $(this.element.querySelector('#popover-tap')).css({
            'left': '50%',
            'border-bottom': '15px solid whitesmoke',
            'border-right': '15px solid transparent',
            'border-left': '15px solid transparent',
            'margin-top': '-30px'
          });
          $(this.element.querySelector('#popover')).css({
            left: popoverLeft,
            'min-height': popoverHeight + 'px',
            'height': popoverHeight + 'px'
          });
          break;

        case spaceTop:
          popoverHeight = Math.max($(this.element.querySelector('#popover')).innerHeight(), ((_$3 = $(this.element.querySelector('.scrollable-container'))) === null || _$3 === void 0 ? void 0 : _$3.innerHeight()) + ((_$4 = $(this.element.querySelector('.bar-nav'))) === null || _$4 === void 0 ? void 0 : _$4.innerHeight()) || 0);
          popoverLeft = Math.max(15, senderCenterX - popoverWidth / 2);
          popoverTop = senderTop - tapWidth - popoverHeight + 5;
          tapTop = popoverHeight;
          $(this.element.querySelector('#popover-tap')).css({
            'left': '50%',
            'border-top': '15px solid whitesmoke',
            'border-right': '15px solid transparent',
            'border-left': '15px solid transparent',
            'margin-bottom': '-30px'
          });
          $(this.element.querySelector('#popover')).css({
            'min-height': popoverHeight + 'px',
            'height': popoverHeight + 'px'
          });
      }

      if (popoverTop < 15) {
        popoverTop = 15;
        this.set('hideTap', true);
      }

      if (popoverTop + popoverHeight > windowHeight) {
        popoverTop = windowHeight - popoverHeight - 15;
        this.set('hideTap', true);
      }

      if (windowWidth - popoverLeft - popoverWidth < 5) popoverLeft = windowWidth - popoverWidth - 5;
      $(this.element.querySelector('#popover')).css({
        'top': popoverTop + 'px',
        'left': popoverLeft + 'px'
      });
      $(this.element.querySelector('#popover-tap')).css({
        'top': tapTop + 'px',
        'left': Math.min(senderCenterX - popoverLeft, popoverWidth - tapWidth)
      });
      this.set('hidden', false);
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this.setup);
    },
    setup: function setup() {
      var _$5;

      (_$5 = $('[id="' + this.get('sender') + '"]')) === null || _$5 === void 0 ? void 0 : _$5.addClass('popover-sender');
      $(this.element.querySelector('.scrollable')).addClass('overflow-scrolling-auto');

      if (this.get('loaded')) {
        this.adjustFrame();
      }
    },
    contextMenu: function contextMenu(e) {
      e.preventDefault();
      return false;
    },
    willDestroyElement: function willDestroyElement() {
      var _$6;

      (_$6 = $('[id="' + this.get('sender') + '"]')) === null || _$6 === void 0 ? void 0 : _$6.removeClass('popover-sender');
      $(this.element.querySelector('.scrollable')).removeClass('overflow-scrolling-auto');

      this._super();
    },
    actions: {
      clickedOverlay: function clickedOverlay() {
        this.clickedOverlay();
        return false;
      }
    }
  });

  _exports.default = _default;
});