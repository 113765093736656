define("mgw/routes/delete-user", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    intercom: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      contactSupport: function contactSupport() {
        var subject = this.intl.t('requestToDeleteUser');
        var body = this.intl.t('requestToDeleteUser__description', {
          email: this.get('currentUser.user.email'),
          name: this.get('currentUser.user.displayName')
        });

        if (this.intercom.isBooted) {
          this.intercom.showNewMessage(body);
        } else {
          var a = document.createElement('a');
          a.href = 'mailto:support@streambim.com?subject=' + subject + '&body=' + body;
          a.click();
        }
      }
    }
  });

  _exports.default = _default;
});