define("mgw/services/nativeservice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Inits a branch.io session and returns the deep link URL in a callback.
    // If no deep link URL is found, the callback is never called.
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    initNativeSession: function initNativeSession(callback) {
      var _this = this;

      if (window.cordova) {
        this._setupPlugins(callback);
      } else if (/cordova/.test(window.navigator.userAgent)) {
        document.addEventListener('deviceready', function () {
          _this._setupPlugins(callback);
        }, false);
      }
    },
    _setupPlugins: function _setupPlugins(callback) {
      this._setupBranch(callback);

      if (this.get('session.isAuthenticated')) {
        this.registerPushNotificationsToken();
      }
    },
    registerPushNotificationsToken: function registerPushNotificationsToken() {
      var _this2 = this;

      if (window.cordova && window.PushNotification) {
        var push = window.PushNotification.init({
          android: {},
          ios: {
            sound: true,
            vibration: true,
            badge: true
          }
        });
        push.on('registration', function (result) {
          var deviceType = window.cordova.platformId;
          var postData = {
            Token: result.registrationId,
            DeviceType: deviceType
          };

          _this2.ajax.post('/mgw/api/v2/push/register', {
            data: postData
          });
        });
        push.on('notification', function (data) {
          console.debug('Received push notification', data);

          if (data.additionalData.deepLink && !data.additionalData.foreground) {
            // The notification has a deeplink and was actually clicked
            _this2.eventservice.stopSession();

            window.location.href = data.additionalData.deepLink;
          }
        });
      }
    },
    _setupBranch: function _setupBranch(callback) {
      var _this3 = this;

      // The resume callback needs to be added after the deviceready event is fired, for some reason...
      document.addEventListener('resume', function () {
        return _this3._initBranchSession(callback);
      }, false);
      return this._initBranchSession(callback);
    },
    _initBranchSession: function _initBranchSession(callback) {
      var success = function success(data) {
        var url = data['$canonical_url'];

        if (callback && url) {
          var dataIdentifier = data['~id'] + data['+click_timestamp'];
          var clickedBranchLinks = JSON.parse(window.localStorage.getItem('clickedBranchLinks') || '{}');

          if (!clickedBranchLinks[dataIdentifier]) {
            clickedBranchLinks[dataIdentifier] = true;
            window.localStorage.setItem('clickedBranchLinks', JSON.stringify(clickedBranchLinks));
            callback(url);
          }
        }
      };

      var fail = function fail(error) {
        console.error('Error initializing branch session', error);
      }; // Support both old and new way of calling branch


      window.Branch.initSession(success, fail).then(success, fail);
    }
  });

  _exports.default = _default;
});