define("mgw/routes/projects", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    activate: function activate() {
      window.enableJqueryDrag();
    },
    deactivate: function deactivate() {
      window.disableJqueryDrag();
    },
    model: function model() {
      return Ember.RSVP.hash({
        features: this.store.findAll("feature"),
        items: this.store.findAll("project-stat"),
        customers: this.store.findAll("customer"),
        staffUsers: this.store.query("user", {
          filter: {
            role: "rendraStaff"
          }
        }),
        projects: this.store.findAll("project")
      });
    }
  });

  _exports.default = _default;
});