define("mgw/components/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: [],
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'title',
    onChange: null,
    tagName: 'select',
    attributeBindings: ['title', 'disabled'],
    value: null,
    _value: null,
    title: null,
    disabled: false,
    context: null,
    observeValue: Ember.observer("value", function () {
      this.updateValue();
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
    },
    updateValue: function updateValue() {
      this.set('_value', this.get('value') + '');
    },
    setup: function setup() {
      var onChangeBound = this.changeHandler.bind(this);
      this.set('onChangeBound', onChangeBound);
      document.getElementById(this.get('elementId')).addEventListener('change', onChangeBound, true);
      this.updateValue();
    },
    willDestroyElement: function willDestroyElement() {
      var _document$getElementB;

      this._super();

      (_document$getElementB = document.getElementById(this.get('elementId'))) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.removeEventListener('change', this.get('onChangeBound'));
    },
    changeHandler: function changeHandler() {
      var selectedIndex = this.$()[0].selectedIndex;
      var options = this.get('options'); // decrement index by 1 if we have a prompt

      var hasPrompt = this.get('prompt') ? true : false;
      var optionIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
      var selection = options[optionIndex] || options[optionIndex];

      try {
        this.$()[0].blur();
      } catch (error) {
        console.warn("Error on dropdown: " + error);
      }

      if (selection) {
        var value = selection[this.get('optionValuePath')];
        var me = this;
        Ember.run(function () {
          me.set('_value', value + '');
          if (me.get('onChange')) me.onChange(selection, me.get('context'));
        });
      }
    }
  });

  _exports.default = _default;
});