define("mgw/controllers/application", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    deviceservice: Ember.inject.service(),
    msie: window.bowser.msie,
    msedge: window.bowser.msedge,
    ios: window.bowser.ios,
    android: window.bowser.android,
    firefox: window.bowser.firefox,
    chrome: window.bowser.chrome,
    _unsupportedBrowser: Ember.computed.or('msie', 'msedge', 'firefox'),
    respectUnsupportedBrowser: true,
    unsupportedBrowser: Ember.computed.and('_unsupportedBrowser', 'respectUnsupportedBrowser'),
    sourcePath: null,
    shouldShowGlobalLogout: Ember.computed('router.currentRouteName', function () {
      var blackList = ['login', 'projects.index', 'projects.project', 'customers.index', 'customers.customer', 'dashboard'];
      return !blackList.includes(this.router.currentRouteName);
    }),
    observeCurrentPath: Ember.observer('router.currentRouteName', function () {
      this.eventservice.updateState();
    }),
    showDashboard: Ember.computed.alias('chrome'),
    selectProjectActive: Ember.computed.equal('router.currentRouteName', 'index'),
    accountActive: Ember.computed.equal('router.currentRouteName', 'user-profile'),
    actions: {
      ignoreUnsupportedBrowser: function ignoreUnsupportedBrowser() {
        this.set('respectUnsupportedBrowser', false);
      }
    }
  });

  _exports.default = _default;
});