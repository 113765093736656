define("mgw/services/popoverservice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = window.$;

  var _default = Ember.Service.extend({
    popovers: [],
    message: {},
    timer: null,
    openPopover: function openPopover(options) {
      var _this = this;

      var popover = Ember.Object.create(options);
      popover.show = true;
      Ember.run.next(function () {
        var _options$options;

        if (!(options === null || options === void 0 ? void 0 : (_options$options = options.options) === null || _options$options === void 0 ? void 0 : _options$options.doNotBlur)) $(':focus').blur();

        _this.get('popovers').pushObject(popover);
      });
    },
    registerPopoverComponent: function registerPopoverComponent(component) {
      this.get('popovers').pushObject(component);
    },
    unRegisterPopoverComponent: function unRegisterPopoverComponent(component) {
      this.get('popovers').removeObject(component);
    },
    closePopover: function closePopover(popover) {
      this.get('popovers').removeObject(popover);
    },
    showMessage: function showMessage(message, duration) {
      var _this2 = this;

      this.set('message', {
        text: message,
        visible: true
      });

      if (this.get('timer')) {
        Ember.run.cancel(this.get('timer'));
      }

      this.set('timer', Ember.run.later(function () {
        _this2.set('message.visible', false);
      }, duration));
    }
  });

  _exports.default = _default;
});