define("mgw/models/cluster-configuration", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    canAddUsers: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    passwordRequirements: _emberData.default.attr({
      readOnly: true
    }),
    isSmtpEnabled: _emberData.default.attr('boolean', {
      readOnly: true
    })
  });

  _exports.default = _default;
});