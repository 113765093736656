define("mgw/helpers/signed-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.format = format;
  _exports.default = void 0;

  function format(params) {
    var number = params[0];
    var string = '';

    if (number > 0) {
      string += '+';
    }

    string += number;
    return string;
  }

  var _default = Ember.Helper.helper(format);

  _exports.default = _default;
});