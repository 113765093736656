define("mgw/routes/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        features: this.store.findAll("feature"),
        projects: this.store.findAll("project"),
        stats: this.store.findAll("projectStat"),
        widgets: this.store.findAll("dashboard-widget")
      });
    },
    beforeModel: function beforeModel() {
      if (!this.currentUser.get('customerAdminFor.length')) {
        // If current user is not rendra staff and not customer admin on any customer, we cannot enter dashboard
        this.transitionTo('index');
      }
    },
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});