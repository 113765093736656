define("mgw/helpers/underline-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.underlineHelper = underlineHelper;
  _exports.default = void 0;

  function underlineHelper(params) {
    var string = params[0] || '';
    var hit = new RegExp(params[1], "i");
    var hitCased = string.match(hit);
    var returnString = string.replace(hit, "<span class='underline'>" + hitCased + "</span>");
    return Ember.String.htmlSafe("<pre>".concat(returnString, "</pre>"));
  }

  var _default = Ember.Helper.helper(underlineHelper);

  _exports.default = _default;
});