define("mgw/models/customer", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    baseUrl: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    kam: _emberData.default.belongsTo('user'),
    name: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    projects: _emberData.default.hasMany('project', {
      readOnly: true
    }),
    customerAdmins: _emberData.default.attr(),
    sfAccount: _emberData.default.attr('string'),
    sfAccountName: _emberData.default.attr('string'),
    ssoProviders: _emberData.default.attr(),
    projectTemplates: _emberData.default.hasMany('projectTemplate')
  });

  _exports.default = _default;
});