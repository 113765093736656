define("mgw/templates/components/add-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U9BWm7bj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,13],null,[[\"config\",\"editModeActive\",\"isEditing\",\"editingWidget\",\"isFirst\",\"isLast\",\"changeEditingWidget\"],[[35,12],[35,11],[35,4],[35,6],[35,10],[35,9],[30,[36,0],[[32,0],\"changeEditingWidget\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"swap--face face--front \",[30,[36,5],[[35,4],\"\",\"face--visible\"],null]]]],[12],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"middle--btn icon--add--circle \",[30,[36,5],[[35,6],\"faded\"],null]]]],[4,[38,0],[[32,0],\"addNewWidget\"],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"swap--face face--back \",[30,[36,5],[[35,4],\"face--visible\"],null]]]],[12],[2,\"\\n\"],[6,[37,8],null,[[\"title\",\"applySettings\",\"cancelEditing\"],[[30,[36,7],[\"newWidget\"],null],[30,[36,0],[[32,0],\"applySettings\"],null],[30,[36,0],[[32,0],\"cancelEditing\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"flex column status-row\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"options\",\"value\",\"optionValuePath\",\"optionLabelPath\",\"onChange\"],[[35,2],[35,1],\"value\",\"name\",[30,[36,0],[[32,0],\"changeWidgetType\"],null]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedWidget\",\"widgetOptions\",\"select-dropdown\",\"isEditing\",\"if\",\"editingWidget\",\"t\",\"edit-widget\",\"isLast\",\"isFirst\",\"editModeActive\",\"config\",\"dashboard-widget\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/add-widget.hbs"
    }
  });

  _exports.default = _default;
});