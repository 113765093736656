define("mgw/controllers/password-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['email', 'success', 'message'],
    message: null,
    email: null,
    success: null,
    infoMessage: Ember.computed('message', 'success', function () {
      var msg = this.message.toLowerCase();
      var success = this.success;

      if (success !== 'false') {
        return 'youHaveSuccessfullyUpdatedThePassword';
      }

      if (msg === 'access expired') {
        return 'thePasswordLinkHasExpired';
      }

      if (msg === 'this url can be used only once') {
        return 'thePasswordLinkHasAlreadyBeenUsed';
      }

      return 'unknownErrorMessage';
    })
  });

  _exports.default = _default;
});