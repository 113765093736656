define("mgw/authenticators/cognito", ["exports", "ember-simple-auth-token/authenticators/jwt"], function (_exports, _jwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    currentUser: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    authenticate: function authenticate(credentials, headers) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this._super(credentials, headers).then(function (res) {
          _this.eventservice.sendEvent('logged-in');

          _this.eventservice.startSession(credentials.username); // Cannot parse the refresh token client side,
          // so storing the issue date in localStorage,
          // so that we can access this later


          res.refreshTokenIssuedAt = new Date();
          resolve(res);
        }).catch(function (err) {
          console.error(err);

          _this.eventservice.sendEvent('login-failed');

          reject(err);
        });
      });
    },
    invalidate: function invalidate() {
      this._super();

      return this.currentUser.unload();
    }
  });

  _exports.default = _default;
});