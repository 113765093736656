define("mgw/mixins/widget-content", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberInViewport.default, {
    store: Ember.inject.service(),
    isDataReady: false,
    // Default config for add-widget widget 
    config: {
      typeName: "add",
      size: [1, 1]
    },
    isEditing: false,
    editingWidget: null,
    project: Ember.computed.alias('config.project'),
    attributeBindings: ['style'],
    style: Ember.computed("config.size", function () {
      return Ember.String.htmlSafe('grid-row-start: span ' + this.get('config.size')[0] + '; grid-column-start: span ' + this.get('config.size')[1] + ';');
    }),
    sizeOptions: [{
      value: '1,1',
      name: '1 x 1'
    }, {
      value: '1,2',
      name: '2 x 1'
    }, {
      value: '2,1',
      name: '1 x 2'
    }, {
      value: '2,2',
      name: '2 x 2'
    }],
    sizeSelectionState: null,
    cardWidgetsCount: 0,
    isFirst: Ember.computed('config.order', function () {
      if (this.get('config.order') === null || this.get('config.order') === undefined) return true;
      return this.get('config.order') === 0;
    }),
    isLast: Ember.computed('config.order', 'cardWidgetsCount', function () {
      if (this.get('config.order') === null || this.get('config.order') === undefined) return true;
      return this.get('config.order') === this.get('cardWidgetsCount') - 1;
    }),
    init: function init() {
      this._super();

      var size = this.get('config.size') || [1, 1];
      this.set('sizeSelectionState', size[0] + ',' + size[1]);
    },
    observeSelectedSize: Ember.observer("config.size", function () {
      this.set('sizeSelectionState', this.get('config.size')[0] + ',' + this.get('config.size')[1]);
    }),
    actions: {
      applySettings: function applySettings() {
        if (this.get('sizeSelectionState')) {
          var newSize = this.get('sizeSelectionState').split(',');
          this.set('config.size', [parseInt(newSize[0]), parseInt(newSize[1])]);
        }

        this.set('isEditing', false);
        this.changeEditingWidget(false);
      },
      cancelEditing: function cancelEditing() {
        this.set('isEditing', false);
        this.changeEditingWidget(false);
      },
      changeSize: function changeSize(selected) {
        this.set('sizeSelectionState', selected.value);
      },
      changeEditingWidget: function changeEditingWidget(bool) {
        this.changeEditingWidget(bool);
      }
    }
  });

  _exports.default = _default;
});