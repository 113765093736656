define("mgw/components/search-textfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    tagName: 'form',
    placeHolder: '',
    value: '',
    disabled: false,
    classNames: 'relative',
    inputClassNames: 'search-textfield',
    icon: true,
    search: '',
    submit: function submit() {
      this.searchChanged(this.get('search'));
    },
    observeClear: Ember.observer("search.length", function () {
      if (this.get('search.length') == 0) this.searchChanged('');
    }),
    actions: {
      focusOut: function focusOut() {
        if (this.get('search.length') == 0) return;
        this.searchChanged(this.get('search'));
      },
      clearSearch: function clearSearch() {
        this.set('search', '');
        this.$('input').focus();
      }
    }
  });

  _exports.default = _default;
});