define("mgw/components/checklists-widget", ["exports", "mgw/mixins/widget-content"], function (_exports, _widgetContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_widgetContent.default, {
    checklistsSortKey: ['name'],
    checklists: [],
    sortedChecklists: Ember.computed.sort('checklists', 'checklistsSortKey'),
    checklistsMap: Ember.computed("checklists.[]", function () {
      var res = {};

      for (var i = 0; i < this.get('checklists.length'); i++) {
        var checklist = this.get('checklists').objectAt(i);
        res[checklist.id] = checklist;
      }

      return res;
    }),
    selectedChecklists: Ember.computed("config.dataIds.[]", "checklistsMap", function () {
      var res = [];

      if (!this.get('config.dataIds') || !this.get('checklistsMap')) {
        return res;
      }

      var _iterator = _createForOfIteratorHelper(this.get('config.dataIds')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var checklistId = _step.value;

          if (this.get('checklistsMap')[checklistId]) {
            res.push(this.get('checklistsMap')[checklistId]);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return res;
    }),
    sortedSelectedChecklists: Ember.computed.sort('selectedChecklists', 'checklistsSortKey'),
    observeSelectedChecklists: Ember.on('init', Ember.observer("selectedChecklists", function () {
      var res = {};

      var _iterator2 = _createForOfIteratorHelper(this.get('selectedChecklists')),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var checklist = _step2.value;
          res[checklist.id] = true;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this.set('checklistSelectionState', res);
    })),
    checklistSelectionState: {},
    observeChecklistSelectionState: Ember.observer('checklistSelectionState', function () {
      this.set('selectAllChecklistsState', Object.keys(this.get('checklistSelectionState')).length === this.get('checklists').length);
    }),
    selectAllChecklistsState: false,
    didEnterViewport: function didEnterViewport() {
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;

      this.set('isDataReady', false);
      this.store.query('checklist-status', {
        projectId: this.get('project.id')
      }).then(function (checklists) {
        if (!_this.isDestroyed) {
          _this.set('checklists', checklists);
        }

        _this.set('isDataReady', true);

        if (_this.get('selectedChecklists').length === 0) {
          _this.set('isEditing', true);
        }
      });
    },
    actions: {
      applySettings: function applySettings() {
        this._super();

        var newSelectedChecklists = [];
        var selectionState = this.get('checklistSelectionState');

        for (var selectedId in selectionState) {
          if (selectionState[selectedId]) {
            newSelectedChecklists.push(selectedId);
          }
        }

        this.set('config.dataIds', newSelectedChecklists);
        this.get('config').save();
      },
      changeChecklistSelection: function changeChecklistSelection(checklistId) {
        var selectionState = this.get('checklistSelectionState');

        if (selectionState[checklistId]) {
          delete selectionState[checklistId];
        } else {
          selectionState[checklistId] = true;
        }

        var newSelectionState = {};

        for (var selectedId in selectionState) {
          newSelectionState[selectedId] = true;
        }

        this.set('checklistSelectionState', newSelectionState);
      },
      changeAllChecklistsSelection: function changeAllChecklistsSelection() {
        if (!this.get('selectAllChecklistsState')) {
          var res = {};

          for (var i = 0; i < this.get('checklists.length'); i++) {
            var checklist = this.get('checklists').objectAt(i);
            res[checklist.id] = true;
          }

          this.set('checklistSelectionState', res);
        } else {
          this.set('checklistSelectionState', {});
        }
      }
    }
  });

  _exports.default = _default;
});