define("mgw/components/export-topics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var component = Ember.Component.extend({
    intl: Ember.inject.service(),
    includeProperty: false,
    propertySet: null,
    propertyKey: null,
    objectProperty: Ember.computed('propertySet', 'propertyKey', function () {
      return [this.get('propertySet'), this.get('propertyKey')].filter(function (val) {
        return val;
      }).join('~');
    }),
    actions: {
      cancel: function cancel() {
        this.close();
      },
      submit: function submit() {
        this.close();
        this.export('_exportTopicsToPowerBI', this.get('objectProperty'));
      }
    }
  });
  var _default = component;
  _exports.default = _default;
});