define("mgw/services/cognito", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    eventservice: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    loginWithCode: function loginWithCode(code, state) {
      var _this$session$data,
          _this$session$data$au,
          _this = this;

      var data = {
        authorizationCode: code,
        provider: state
      };

      if ((_this$session$data = this.session.data) === null || _this$session$data === void 0 ? void 0 : (_this$session$data$au = _this$session$data.authenticated) === null || _this$session$data$au === void 0 ? void 0 : _this$session$data$au.idToken) {
        var _this$session$data2, _this$session$data2$a;

        data.idToken = (_this$session$data2 = this.session.data) === null || _this$session$data2 === void 0 ? void 0 : (_this$session$data2$a = _this$session$data2.authenticated) === null || _this$session$data2$a === void 0 ? void 0 : _this$session$data2$a.idToken;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax.post('/mgw/api/v2/federation/loginwithcode', {
          data: data
        }).then(function (res) {
          _this.currentUser.load().finally(function () {
            resolve(res);
          });
        }).catch(function (err) {
          var _err$payload, _err$payload2;

          console.log(err);

          if ((err === null || err === void 0 ? void 0 : (_err$payload = err.payload) === null || _err$payload === void 0 ? void 0 : _err$payload.error) == 'no provider matches ') {
            alert('Unsupported provider (' + state + ')');
          }

          reject((err === null || err === void 0 ? void 0 : (_err$payload2 = err.payload) === null || _err$payload2 === void 0 ? void 0 : _err$payload2.error) || err);
        });
      });
    },
    loginGuest: function loginGuest(username, password) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.ajax.post('/mgw/api/v2/guest-login/' + password, {
          data: {}
        }).then(function (res) {
          _this2.eventservice.sendEvent('logged-in');

          _this2.eventservice.startSession(username);

          resolve(res);
        }).catch(function (err) {
          console.log(err);

          _this2.eventservice.sendEvent('login-failed');

          reject(err);
        });
      });
    },
    createFederated: function createFederated(provider, refreshToken, proposedUsername) {
      var data = {
        Provider: provider,
        ProposedUsername: proposedUsername,
        RefreshToken: refreshToken
      };
      return window.jQuery.ajax({
        url: '/mgw/api/v2/federation/create',
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: JSON.stringify(data)
      });
    },
    logout: function logout() {
      this.eventservice.sendEvent('logged-out');
    }
  });

  _exports.default = _default;
});