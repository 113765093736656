define("mgw/components/project-resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    name: null,
    icon: null,
    item: null,
    count: null,
    colors: null,
    selected: false,
    showCaret: false,
    isExpanded: false,
    indentStyle: 'min-width: 0px',
    allFromTypeItem: Ember.computed('item.value', function () {
      return this.get('item.key') && !this.get('item.value');
    }),
    isPrefix: Ember.computed('item.key', function () {
      if (!this.get('item.key') || !this.get('item.value')) return false;
      return this.get('item.key').endsWith('Prefix') || this.get('item.key') === 'folderId';
    }),
    colorsStyles: Ember.computed('colors', function () {
      if (!this.get('colors')) return [];
      var colorsArray = Ember.isArray(this.get('colors')) ? this.get('colors') : [this.get('colors')];
      return colorsArray.map(function (color, index) {
        return "background-color: " + color + "; right: " + (10 + 5 * index) + "px;";
      });
    }),
    _name: Ember.computed('name', 'item', 'allFromTypeItem', function () {
      if (this.get('allFromTypeItem')) return this.intl.t('everything'); // For the options to select all from type

      if (this.get('item.key') === 'folderId' && this.get('item.value') === '0') return 'root'; // For the root folder, to avoid haivng empty name

      return this.get('name') || this.get('item.renameTo') || this.get('item.name') || this.get('item.value');
    }),
    actions: {
      selectItem: function selectItem() {
        if (this.onSelect) this.onSelect(this.get('item'));
        return false;
      },
      toggleExpand: function toggleExpand() {
        if (this.onToggleExpand) this.onToggleExpand();
        return false;
      },
      deleteItem: function deleteItem() {
        this.onDelete(this.get('item'));
      }
    }
  });

  _exports.default = _default;
});