define("mgw/templates/components/search-textfield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jKuy74V5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],null,[[\"type\",\"value\",\"classNames\",\"placeholder\",\"focus-out\",\"autocomplete\",\"disabled\"],[\"search\",[35,5],[35,4],[35,3],[30,[36,0],[[32,0],\"focusOut\"],null],[35,2],[35,1]]]]],[2,\"\\n\"],[6,[37,7],[[35,5,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"button\"],[24,0,\"icon--clear btn--clear-search\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"clearSearch\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"disabled\",\"autocomplete\",\"placeholder\",\"inputClassNames\",\"search\",\"input\",\"if\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/search-textfield.hbs"
    }
  });

  _exports.default = _default;
});