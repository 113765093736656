define("mgw/routes/new-project/created", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('project', params.project_id, {
        reload: true
      });
    }
  });

  _exports.default = _default;
});