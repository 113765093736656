define("mgw/components/x-tree-collapsed-icon", ["exports", "ember-simple-tree/components/x-tree-collapsed-icon"], function (_exports, _xTreeCollapsedIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xTreeCollapsedIcon.default;
    }
  });
});