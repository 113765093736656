define("mgw/templates/projects/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p4tjbJRT",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"close\"],[[35,1],[30,[36,0],[[32,0],\"close\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"edit-project\"]}",
    "meta": {
      "moduleName": "mgw/templates/projects/project.hbs"
    }
  });

  _exports.default = _default;
});