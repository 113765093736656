define("mgw/routes/projects/project", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        projectStats: this.store.findRecord('project-stat', params.project_id),
        users: this.store.query('project-member', {
          'filter[project]': params.project_id
        }),
        project: this.store.findRecord('project', params.project_id),
        features: this.store.findAll('feature'),
        customers: this.store.findAll('customer')
      });
    },
    setupController: function setupController(controller, model) {
      model.project.pinRelationships();

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});