define("mgw/controllers/new-project/index", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    isSubmitting: false,
    defaultTemplates: Ember.computed.filterBy('model.templateProjects', 'isDefault'),
    regularTemplates: Ember.computed.filterBy('model.templateProjects', 'regular'),
    selectedTemplate: null,
    customersWithProjectTemplates: Ember.computed('model.projectTemplates', function () {
      return this.get('model.projectTemplates').filterBy('customer.id').sortBy('customer.name').mapBy('customer').uniqBy('id');
    }),
    sendToZap: function sendToZap() {
      // Don't send to zap, unless we're in production
      var payload = JSON.stringify({
        email: this.get('currentUser.user.id'),
        givenName: this.get('currentUser.user.givenName'),
        familyName: this.get('currentUser.user.familyName'),
        organization: this.get('currentUser.user.organization'),
        phone: this.get('currentUser.user.phone'),
        title: this.get('currentUser.user.title'),
        projectName: this.get('model.project.name'),
        locale: this.intl.locale[0]
      });

      if (_environment.default.environment !== 'production') {
        console.log(payload);
        return Ember.RSVP.Promise.resolve();
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest(); // FIXME: Remove hardcoding of zap hook

        xhr.open("POST", "https://hooks.zapier.com/hooks/catch/1981473/sy9oht/");
        xhr.onload = resolve;
        xhr.onerror = reject;
        xhr.send(payload);
      });
    },
    actions: {
      createNewProject: function createNewProject() {
        var _this = this;

        if (this.get('selectedTemplate') && this.get('selectedTemplate') != "null") {
          var templateType = this.get('selectedTemplate').split('-')[0];
          var templateId = this.get('selectedTemplate').split('-')[1];

          if (templateType == 'project') {
            this.set('model.project.templateProjectId', templateId);
            this.set('model.project.projectTemplateId', null);
          } else {
            this.set('model.project.templateProjectId', null);
            this.set('model.project.projectTemplateId', templateId);
          }
        } else {
          this.set('model.project.templateProjectId', null);
          this.set('model.project.projectTemplateId', null);
        }

        this.set('isSubmitting', true); // Send to mgw first, then to zap, then move over to the project URI we're given

        var projectId = null; // We need this to be jsonapi

        this.get('model.project').save().then(function (project) {
          projectId = project.get('id');
          return _this.sendToZap();
        }).then(function () {
          return _this.transitionToRoute('new-project.created', projectId).promise;
        }).finally(function () {
          return _this.set('isSubmitting', false);
        }).catch(console.warn);
      }
    }
  });

  _exports.default = _default;
});