define("mgw/models/project-template", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr("string"),
    content: _emberData.default.attr(),
    customer: _emberData.default.belongsTo('customer')
  });

  _exports.default = _default;
});