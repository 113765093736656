define("mgw/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute('login'), this.toRoute('index'), this.use('fade'));
    this.transition(this.fromRoute('new-project'), this.use('fade'), this.reverse('fade'));
  }
});