define("mgw/helpers/array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = void 0;

  function arrayContains(params) {
    if (!params || params.length < 2) return false;
    var array = params[0];

    if (Ember.isNone(array)) {
      array = [];
    }

    var value = params[1];
    var property = null;

    if (params.length > 2) {
      property = params[2];
    }

    if (property === null) {
      return array.includes(value);
    } else {
      return array.find(function (x) {
        return x[property] === value;
      }) !== undefined;
    }
  }

  var _default = Ember.Helper.helper(arrayContains);

  _exports.default = _default;
});