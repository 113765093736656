define("mgw/models/template-project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    isDefault: _emberData.default.attr('boolean'),
    isLibrary: _emberData.default.attr('boolean'),
    regular: Ember.computed('isDefault', 'isLibrary', function () {
      if (this.get('name') == '<empty>') return false;
      return !this.get('isDefault') && !this.get('isLibrary');
    })
  });

  _exports.default = _default;
});