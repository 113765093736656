define("mgw/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    nativeservice: Ember.inject.service(),
    idleservice: Ember.inject.service(),
    user: null,
    customers: null,
    promise: Ember.RSVP.resolve(),
    emailCandidateForLogin: '',
    emailToAutofill: Ember.computed.or('user.email', 'emailCandidateForLogin'),
    load: function load() {
      var _this = this;

      if (!this.idleservice.isInitiated) {
        this.idleservice.init();
      }

      if (this.get('session.isAuthenticated') && !this.get('session.data.authenticated.usernamePending')) {
        var promise = Ember.RSVP.hash({
          users: this.store.query('user-profile', {
            filter: {
              profile: true
            }
          }),
          customers: this.store.findAll('customer')
        }).then(function (res) {
          _this.set('user', res.users.get('firstObject'));

          _this.set('customers', res.customers);

          var customerAdminFor = res.customers.filter(function (customer) {
            return _this.get('user.role') == 'rendra_staff' || customer.get('customerAdmins.length') && customer.get('customerAdmins').includes(_this.get('user.id'));
          }).sortBy('name');

          _this.set('customerAdminFor', customerAdminFor);

          _this.nativeservice.registerPushNotificationsToken();
        });
        this.set('promise', promise);
        return promise;
      } else {
        return Ember.RSVP.resolve();
      }
    },
    unload: function unload() {
      var _this2 = this;

      // Wait for earlier loads/unloads, then unload/remove user. Seems very
      // unlikely that this will be an issue, so debatable whether we need to
      // handle race conditions here and make the logic more complicated. But oh
      // well.
      var removeUser = function removeUser() {
        console.info("Unloading user, if they exist");
        var user = _this2.user;

        _this2.set('user', null);

        if (user) {
          _this2.store.unloadRecord(user);
        }
      };

      var promise = this.promise.then(removeUser, removeUser);
      this.set('promise', promise);
      return promise;
    }
  });

  _exports.default = _default;
});