define("mgw/components/dashboard-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    // input
    config: null,
    editModeActive: false,
    editingWidget: null,
    isFirst: false,
    isLast: false,
    tagName: 'div',
    classNameBindings: [':dashboard-widget', 'editingOtherWidget:faded'],
    editingOtherWidget: Ember.computed('editingWidget', 'isEditing', function () {
      return this.editingWidget && !this.isEditing;
    }),
    observeEditMode: Ember.observer("editModeActive", function () {
      if (!this.get('editModeActive')) {
        var me = this;
        Ember.run.next(function () {
          me.set('isEditing', false);
          me.changeEditingWidget(false);
        });
      }
    }),
    actions: {
      delete: function _delete() {
        this.deleteWidget(this.get('config'));
      },
      editWidget: function editWidget() {
        this.changeEditingWidget(true);
        this.set('isEditing', true);
      },
      moveLeft: function moveLeft() {
        this.moveWidget(this.get('config'), -1);
      },
      moveRight: function moveRight() {
        this.moveWidget(this.get('config'), 1);
      }
    }
  });

  _exports.default = _default;
});