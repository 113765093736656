define("mgw/components/edit-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: 'Edit',
    classNames: ['flex', 'column', 'height-100', 'outlined-shadow'],
    sizeOptions: null,
    selectedSize: null,
    actions: {
      applySettings: function applySettings() {
        this.applySettings();
      },
      cancelEditing: function cancelEditing() {
        this.cancelEditing();
      }
    }
  });

  _exports.default = _default;
});