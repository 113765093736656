define("mgw/mixins/with-snapshot-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    instancesDone: _emberData.default.attr('number', {
      readOnly: true
    }),
    instancesInProgress: _emberData.default.attr('number', {
      readOnly: true
    }),
    instancesNotStarted: _emberData.default.attr('number', {
      readOnly: true
    }),
    instancesTotal: _emberData.default.attr('number', {
      readOnly: true
    }),
    topicsTotal: _emberData.default.attr('number', {
      readOnly: true
    }),
    topicsOpen: _emberData.default.attr('number', {
      readOnly: true
    }),
    topicsClosed: _emberData.default.attr('number', {
      readOnly: true
    }),
    snapshotName: Ember.computed('type', 'repeatEvery', 'startDate', 'endDate', 'monthsInYear.[]', 'daysInMonth.[]', function () {
      var m = window.moment(new Date());

      if (['checklistSnapshot', 'checklistSnapshotStat'].includes(this.get('type'))) {
        m = window.moment(new Date(this.get('startDate')));
      } else {
        if (this.get('endDate')) m = window.moment(new Date(this.get('endDate')));
      }

      var name = '';

      switch (this.get('repeatEvery')) {
        case 'day':
          {
            if (window.moment().diff(m, 'weeks') === 0) name = m.format('dddd, Do MMM YYYY');else if (window.moment().diff(m, 'months') === 0) name = m.format('ddd DD');else if (window.moment().diff(m, 'year') === 0) name = m.format('ddd DD MMM');else name = m.format('ddd DD MMM YYYY');
            break;
          }

        case 'week':
          {
            if (window.moment().diff(m, 'year') === 0) name = this.intl.t('week') + ' ' + m.week();else name = this.intl.t('week') + ' ' + m.week() + ', ' + m.year();
            break;
          }

        case 'month':
          {
            var format = 'MMM';
            if (window.moment().diff(m, 'year') === 0) format = format += ' YYYY';
            if (this.get('daysInMonth.length') === 1) format = 'DD ' + format;
            name = m.format(format);
            break;
          }

        case 'year':
          {
            if (this.get('monthsInYear.length') === 1) name = m.year();else name = m.format('MMM YYYY');
          }
      }

      return name;
    })
  });

  _exports.default = _default;
});