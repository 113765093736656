define("mgw/components/edit-customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    tagName: '',
    projects: Ember.computed('model.projects.[]', function () {
      return this.get('model.projects').toArray().mapBy('name').join(', ');
    }),
    selectedProviders: Ember.computed('model.ssoProviders', function () {
      var _this = this;

      if (!this.get('model.ssoProviders.length')) return [];
      var selectedProviders = [];
      this.get('model.ssoProviders').forEach(function (provider) {
        var providerObj = _this.ssoproviderservice.getProviderInfo(provider);

        if (providerObj) {
          selectedProviders.push(providerObj);
        } else {
          console.warn('Not recognized provider: ' + provider);
        }
      });
      return selectedProviders;
    }),
    searchUsers: function searchUsers(term) {
      return [term];
    },
    handleKeydown: function handleKeydown(dropdown, e) {
      if (e.keyCode == 13) {
        // Enter - prevent default to avoid the entire form to be submitted
        e.preventDefault();
      }
    },
    actions: {
      onKamChange: function onKamChange(userID) {
        if (userID == "none") {
          this.model.set('kam', null);
        } else {
          var kam = this.store.peekRecord('user', userID);
          this.model.set('kam', kam);
        }
      },
      onSSOProviderChange: function onSSOProviderChange(providers) {
        this.set('model.ssoProviders', providers.map(function (provider) {
          return provider.id;
        }));
      },
      cancel: function cancel() {
        if (this.get('model.isNew')) {
          this.model.deleteRecord();
        } else {
          this.model.rollbackAttributes();
        }

        this.close();
      },
      delete: function _delete() {
        var _this2 = this;

        if (window.confirm("Do you really want to delete this customer?")) {
          this.model.destroyRecord().then(function () {
            _this2.close();
          }, function (response) {
            window.alert("Unable to delete customer: ".concat(response.errors[0].detail));
          });
        }
      },
      submit: function submit() {
        var _this3 = this;

        this.model.save().then(function () {
          _this3.close();
        }, function (response) {
          window.alert("Unable to save customer reason: ".concat(response.errors[0].detail));

          _this3.close();
        });
      }
    }
  });

  _exports.default = _default;
});