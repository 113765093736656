define("mgw/controllers/invalid-existing-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['email'],
    email: null,
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    passwordservice: Ember.inject.service(),
    errorMessage: null,
    hasResetPassword: false,
    actions: {
      resetInvalidPassword: function resetInvalidPassword() {
        var _this = this;

        this.ajax.post("/mgw/api/v2/change-password/for-invalid", {
          data: {
            username: this.email,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
          }
        }).then(function () {
          _this.set('hasResetPassword', true);
        }).catch(function (err) {
          _this.set('hasErrorMessage', true);

          _this.set('errorMessage', err.payload.error);
        });
      }
    }
  });

  _exports.default = _default;
});