define("mgw/helpers/read-path-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readPath = readPath;
  _exports.default = void 0;

  function readPath(params) {
    if (!params || params.length !== 2) return null;
    var object = params[0];
    var path = params[1];

    try {
      return Ember.get(object, path);
    } catch (e) {
      return null;
    }
  }

  var _default = Ember.Helper.helper(readPath);

  _exports.default = _default;
});