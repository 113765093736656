define("mgw/models/checklist-status", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr('string'),
    instancesDone: _emberData.default.attr('number'),
    instancesInProgress: _emberData.default.attr('number'),
    instancesNotStarted: _emberData.default.attr('number'),
    instancesTotal: _emberData.default.attr('number'),
    todayInstancesDone: _emberData.default.attr('number'),
    todayInstancesInProgress: _emberData.default.attr('number'),
    todayInstancesNotStarted: _emberData.default.attr('number')
  });

  _exports.default = _default;
});