define("mgw/models/project-stat", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    project: _emberData.default.belongsTo("project"),
    error: _emberData.default.attr("string"),
    fromTemplateId: _emberData.default.attr("number"),
    numBuildings: _emberData.default.attr("number"),
    numDocuments: _emberData.default.attr("number"),
    numFolders: _emberData.default.attr("number"),
    numGroups: _emberData.default.attr("number"),
    numIfc: _emberData.default.attr("number"),
    numIfcModels: _emberData.default.attr("number"),
    numTemplates: _emberData.default.attr("number"),
    numTopics: _emberData.default.attr("number"),
    numActualTopics: _emberData.default.attr("number"),
    numOpenTopics: _emberData.default.attr("number"),
    numDoneTopics: _emberData.default.attr("number"),
    numClosedTopics: _emberData.default.attr("number"),
    numChecklists: _emberData.default.attr("number"),
    numUsers: _emberData.default.attr("number"),
    publicTemplateOrder: _emberData.default.attr("number"),
    customFloorplans: _emberData.default.attr("number"),
    ifcFileSize: _emberData.default.attr("number"),
    numSpaces: _emberData.default.attr("number"),
    features: _emberData.default.hasMany("features")
  });

  _exports.default = _default;
});