define("mgw/models/workflow-status", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    workflowName: _emberData.default.attr('string'),
    totalOpen: _emberData.default.attr('number'),
    totalDone: _emberData.default.attr('number'),
    totalClosed: _emberData.default.attr('number'),
    openedToday: _emberData.default.attr('number'),
    doneToday: _emberData.default.attr('number'),
    closedToday: _emberData.default.attr('number'),
    total: Ember.computed('totalOpen', 'totalDone', 'totalClosed', function () {
      return this.get('totalOpen') + this.get('totalDone') + this.get('totalClosed');
    })
  });

  _exports.default = _default;
});