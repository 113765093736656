define("mgw/authenticators/cognito-guest", ["exports", "mgw/authenticators/cognito"], function (_exports, _cognito) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cognito.default.extend({
    cognito: Ember.inject.service(),
    authenticate: function authenticate(username, password) {
      return this.cognito.loginGuest(username, password);
    }
  });

  _exports.default = _default;
});