define("mgw/routes/password-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model(params) {
      if (params.email) {
        this.set('currentUser.emailCandidateForLogin', params.email);
      }

      return null;
    }
  });

  _exports.default = _default;
});