define("mgw/components/image-widget", ["exports", "mgw/mixins/widget-content"], function (_exports, _widgetContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widgetContent.default, {
    didEnterViewport: function didEnterViewport() {
      this.loadData();
    },
    loadData: function loadData() {
      this.set('isDataReady', true);
    }
  });

  _exports.default = _default;
});