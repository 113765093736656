define("mgw/templates/customers/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eOtcC72m",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"model\",\"staffUsers\",\"close\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"close\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"staffUsers\",\"model\",\"edit-customer\"]}",
    "meta": {
      "moduleName": "mgw/templates/customers/customer.hbs"
    }
  });

  _exports.default = _default;
});