define("mgw/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "mgw/config/environment", "ember-local-storage", "ember-concurrency"], function (_exports, _applicationRouteMixin, _environment, _emberLocalStorage, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    nativeservice: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    intercom: Ember.inject.service(),
    intl: Ember.inject.service(),
    passwordservice: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    failedToAutoLogin: false,
    isCordova: function isCordova() {
      return /cordova/.test(window.navigator.userAgent);
    },
    isOnNativeDevice: function isOnNativeDevice() {
      var isIPad = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0 || navigator.platform === 'iPad';
      return window.bowser.ios || window.bowser.android || isIPad;
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      // introcuce backdoor for branch.io via URL query param
      if (this.isCordova() || location.href.includes('forceBrowser=false')) {
        sessionStorage.removeItem('forceBrowser');
      } else if (!sessionStorage.getItem('forceBrowser') && location.href.includes('forceBrowser=true')) {
        sessionStorage.setItem('forceBrowser', 'true');
      }

      this.nativeservice.initNativeSession(function (branchUrl) {
        if (branchUrl != transition.to.queryParams.next && branchUrl != window.location.href) {
          _this.eventservice.stopSession();

          window.location.href = branchUrl;
        }
      });

      if (!this.isCordova() && this.isOnNativeDevice() && !sessionStorage.getItem('forceBrowser') && _environment.default.environment != 'local') {
        // Not on native, but on mobile. Fetch deeplink, unless applicationCache is busy.
        transition.abort();
        var appStoreName = window.bowser.android ? "Google Play" : "App Store";
        var body = {
          branch_key: _environment.default.branch.key,
          data: {
            $canonical_url: window.location.href,
            $one_time_use: true,
            $og_title: 'StreamBIM' + '.',
            $og_description: this.intl.t('streamingOfBIMAndDrawingsOnSite'),
            downloadTheAppFromText: this.intl.t('downloadTheAppFrom', {
              appStoreName: appStoreName
            }),
            getTheAppText: this.intl.t('getTheApp'),
            openTheAppText: this.intl.t('open'),
            backgroundImage: window.location.origin + '/assets/images/background.jpg',
            logoImage: window.location.origin + '/assets/images/logo.svg'
          }
        };
        window.jQuery.ajax({
          url: 'https://api.branch.io/v1/url',
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: JSON.stringify(body)
        }).then(function (response) {
          _this.eventservice.stopSession();

          window.location.href = response.url;
        }, function (err) {
          console.warn(err);
        });
      }

      var promises = [this._loadCurrentUser(), this._loadClusterConfig()];
      return (0, _emberConcurrency.allSettled)(promises);
    },
    setupController: function setupController(controller) {
      // storing the sourcepath in order to be able to dislpay the original source path on IE after rerouting.
      controller.set('sourcePath', window.location.href);
    },
    sessionAuthenticated: function sessionAuthenticated() {
      console.debug('sessionAuthenticated()');

      this._loadCurrentUser();
    },
    sessionInvalidated: function sessionInvalidated() {
      // This feels a bit hacky, but there's only one sessionInvalidated, so
      // everything is funneled through this endpoint
      if (this.get('session.dontReloadOnInvalidation')) {
        this.set('session.dontReloadOnInvalidation', false);
        return;
      }

      this._super.apply(this, arguments);
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return this.currentUser.load().catch(function () {
        return _this2.session.invalidate();
      });
    },
    _loadClusterConfig: function _loadClusterConfig() {
      return this.passwordservice.load();
    },
    actions: {
      help: function help() {
        if (!this.get('eventservice.sessionRunning')) {
          // Start Intercom anonymously
          this.intercom.boot({
            app_id: _environment.default.intercom.appId,
            language_override: this.intl.locale[0]
          });
        }

        this.intercom.show();
      },
      logout: function logout() {
        this.transitionTo('logout');
      },
      copyTextToClipboard: function copyTextToClipboard(url, message) {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(url);
        } else {
          var textArea = document.createElement('textarea');
          textArea.value = url;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
        }

        this.get('popoverservice').showMessage(message, 3000);
        return false;
      }
    }
  });

  _exports.default = _default;
});