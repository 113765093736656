define("mgw/templates/components/dashboard-project-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gGSIiOuV",
    "block": "{\"symbols\":[\"widget\",\"index\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"dashboard-card grid \",[30,[36,3],[[35,1],\"editingWidget\"],null]]]],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,7],[[30,[36,6],[[32,1,[\"typeName\"]],\"-widget\"],null]],[[\"config\",\"editModeActive\",\"editingWidget\",\"moveWidget\",\"deleteWidget\",\"cardWidgetsCount\",\"changeEditingWidget\"],[[32,1],[35,5],[35,1],[30,[36,0],[[32,0],\"moveWidget\"],null],[30,[36,0],[[32,0],\"deleteWidget\"],null],[35,4],[30,[36,0],[[32,0],\"changeEditingWidget\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[6,[37,3],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"editingWidget\",\"addWidget\",\"changeEditingWidget\"],[[35,1],[30,[36,0],[[32,0],\"addWidget\"],null],[30,[36,0],[[32,0],\"changeEditingWidget\"],null]]]]],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"corner--btn icon--x--circle \",[30,[36,3],[[35,1],\"faded\"],null]]]],[4,[38,0],[[32,0],\"deleteCard\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"editingWidget\",\"add-widget\",\"if\",\"numWidgets\",\"editModeActive\",\"concat\",\"component\",\"orderedWidgets\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/dashboard-project-card.hbs"
    }
  });

  _exports.default = _default;
});