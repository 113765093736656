define("mgw/services/ajax", ["exports", "ember-ajax/services/ajax", "ember-ajax/errors"], function (_exports, _ajax, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    contentType: 'application/json; charset=utf-8',
    headers: Ember.computed('session.data.authenticated.idToken', function () {
      var accessToken = this.get('session.data.authenticated.idToken');

      if (accessToken) {
        return {
          Authorization: "Bearer ".concat(accessToken)
        };
      } else {
        return {};
      }
    }),
    handleResponse: function handleResponse() {
      var result = this._super.apply(this, arguments);

      if ((0, _errors.isUnauthorizedError)(result) && !this.get('session.data.authenticated.usernamePending')) {
        this.session.invalidate();
      }

      return result;
    }
  });

  _exports.default = _default;
});