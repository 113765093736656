define("mgw/instance-initializers/lasturl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'lasturl',
    initialize: function initialize() {
      if (window.location.href.indexOf("fromNative=true") != -1) {
        var lastUrl = window.localStorage.getItem('lastUrl');
        var lastUrlCookie = ('; ' + document.cookie).split("; lastUrl=").pop().split(';')[0];

        if (lastUrlCookie) {
          lastUrl = decodeURIComponent(lastUrlCookie);
          window.localStorage.setItem('lastUrl', lastUrl);
        }

        if (lastUrl) {
          window.location.href = lastUrl; // Return unresolved promise to stop the app from continue loading

          return new Ember.RSVP.Promise();
        }
      } else {
        window.localStorage.removeItem('lastUrl');
      }
    }
  };
  _exports.default = _default;
});