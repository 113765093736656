define("mgw/templates/components/dashboard-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "11QhDJEn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"dashboard-widget-content swappable \",[30,[36,5],[[35,6],\"show--back\"],null]]]],[12],[2,\"\\n\\n  \"],[18,1,null],[2,\"\\n\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"widget-overlay on--edit hover-container\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"middle--btn icon--settings--circle show-on-hover\"],[4,[38,0],[[32,0],\"editWidget\"],null],[12],[13],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"left--btn icon--caret--left show-on-hover\"],[4,[38,0],[[32,0],\"moveLeft\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"right--btn icon--caret--right show-on-hover\"],[4,[38,0],[[32,0],\"moveRight\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"corner--btn icon--x--circle show-on-hover\"],[4,[38,0],[[32,0],\"delete\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isFirst\",\"unless\",\"isLast\",\"deleteWidget\",\"if\",\"isEditing\",\"editModeActive\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/dashboard-widget.hbs"
    }
  });

  _exports.default = _default;
});