define("mgw/services/ssoproviderservice", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ssoProviders: _environment.default.idProviders,
    ssoProvidersDict: Ember.computed('ssoProviders', function () {
      var _this$get;

      var providersDict = {};
      (_this$get = this.get('ssoProviders')) === null || _this$get === void 0 ? void 0 : _this$get.forEach(function (provider) {
        providersDict[provider.id.toUpperCase()] = provider;
      });
      return providersDict;
    }),
    getProviderInfo: function getProviderInfo(provider) {
      if (provider) {
        return this.get('ssoProvidersDict')[provider.toUpperCase()];
      } else return null;
    }
  });

  _exports.default = _default;
});