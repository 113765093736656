define("mgw/services/idleservice", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userIdle.default.extend({
    session: Ember.inject.service(),
    isInitiated: false,
    init: function init() {
      this.isInitiated = true;
      this.set('IDLE_TIMEOUT', 60 * 60 * 1000); // 60 minutes

      this._super.apply(this, arguments);
    },
    setIdle: function setIdle() {
      this._super.apply(this, arguments);

      if (this.get('session.session.authenticated.logoutWhenIdle')) {
        this.get('session').invalidate();
      }
    }
  });

  _exports.default = _default;
});