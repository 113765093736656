define("mgw/services/intercom", ["exports", "ember-intercom-io/services/intercom", "mgw/config/environment"], function (_exports, _intercom, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$get;

  var _default = _intercom.default.extend({
    config: (_Ember$get = Ember.get(_environment.default, 'intercom')) !== null && _Ember$get !== void 0 ? _Ember$get : {}
  });

  _exports.default = _default;
});