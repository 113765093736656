define("mgw/authenticators/cognito-public", ["exports", "mgw/authenticators/cognito"], function (_exports, _cognito) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cognito.default.extend({
    refreshAccessTokens: false,
    authenticate: function authenticate(credentials, headers) {
      return this._super(credentials, headers).then(function (res) {
        res.logoutWhenIdle = true;
        delete res.refreshToken;
        return res;
      });
    }
  });

  _exports.default = _default;
});