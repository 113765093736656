define("mgw/templates/components/edit-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mxy2a261",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"header\"],[14,0,\"flex bar--medium bar--light space-between\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bar-medium-btn\"],[4,[38,5],[[32,0],\"applySettings\"],null],[12],[1,[30,[36,0],[\"ok\"],null]],[13],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"bar-medium-title\"],[12],[1,[34,6]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bar-medium-btn pull-right\"],[4,[38,5],[[32,0],\"cancelEditing\"],null],[12],[1,[30,[36,0],[\"cancel\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex column height-width-100 scrollable white-back\"],[12],[2,\"\\n\"],[6,[37,7],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex widget-options-row options-group-header\"],[12],[1,[30,[36,0],[\"size\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex column widget-options-row\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"options\",\"value\",\"optionValuePath\",\"optionLabelPath\",\"onChange\"],[[35,3],[35,2],\"value\",\"name\",[35,1]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,1,null],[2,\"\\n    \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"changeSize\",\"selectedSize\",\"sizeOptions\",\"select-dropdown\",\"action\",\"title\",\"if\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/edit-widget.hbs"
    }
  });

  _exports.default = _default;
});