define("mgw/models/project-member", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    project: _emberData.default.belongsTo('project', {
      readOnly: true
    }),
    user: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    privilegeLevel: _emberData.default.attr('number', {
      readOnly: true
    }),
    invited: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    invitedBy: _emberData.default.belongsTo('user', {
      readOnly: true
    })
  });

  _exports.default = _default;
});