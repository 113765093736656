define("mgw/routes/new-project/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "mgw/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    afterModel: function afterModel(model) {
      if (model.user.get('role') != 'rendra_staff' && _environment.default.environment == 'on-premise') {
        return this.transitionTo('index');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.findRecord("user", this.get("currentUser.user.id")),
        project: this.store.createRecord('project'),
        templates: this.store.findAll("template-project"),
        templateProjects: this.store.findAll("template-project"),
        projectTemplates: this.store.findAll("project-template")
      });
    },
    setupController: function setupController(controller, model) {
      var defaultTemplate = model.templates.findBy('isDefault');
      model.project.set('templateProjectId', defaultTemplate.get('id'));
      controller.set('model', model);
    }
  });

  _exports.default = _default;
});