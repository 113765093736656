define("mgw/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (attribute.options != null ? attribute.options.readOnly : undefined) {
        return;
      }

      return this._super.apply(this, arguments);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (relationship.options != null ? relationship.options.readOnly : undefined) {
        return;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});