define("mgw/components/add-widget", ["exports", "mgw/mixins/widget-content"], function (_exports, _widgetContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widgetContent.default, {
    intl: Ember.inject.service(),
    selectedWidget: 'generic-info',
    widgetOptions: Ember.computed('intl.locale', function () {
      return [{
        value: 'generic-info',
        name: this.intl.t('genericInfo')
      }, {
        value: 'workflows',
        name: this.intl.t('workflowsTable')
      }, {
        value: 'workflow-chart',
        name: this.intl.t('workflowHistogram')
      }, {
        value: 'checklists',
        name: this.intl.t('checklistsTable')
      } // { value: 'image', name: 'Sample image'  }, wait till upload is ready
      ];
    }),
    actions: {
      addNewWidget: function addNewWidget() {
        this.set('isEditing', true);
        this.changeEditingWidget(true);
      },
      applySettings: function applySettings() {
        this._super();

        this.addWidget(this.get('selectedWidget'));
      },
      changeWidgetType: function changeWidgetType(selected) {
        this.set('selectedWidget', selected.value);
      }
    }
  });

  _exports.default = _default;
});