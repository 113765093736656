define("mgw/models/ad-import-step", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    group: _emberData.default.attr('string'),
    levelnew: _emberData.default.attr('number'),
    levelold: _emberData.default.attr('number'),
    project: _emberData.default.attr('number'),
    error: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    user: _emberData.default.attr('string'),
    gateway: _emberData.default.attr('boolean'),
    type: Ember.computed('group', 'project', 'user', function () {
      if (this.gateway && this.user) {
        return 'User';
      } else if (this.project && this.group && this.user) {
        return 'Group membership';
      } else if (this.project && this.group) {
        return 'Group';
      } else if (this.project && this.user) {
        return 'Project access';
      } else {
        return 'Other'; // ?
      }
    }),
    roles: {
      30: 'owner',
      20: 'admin',
      10: 'user',
      0: 'inactive'
    },
    action: Ember.computed('type', 'levelnew', 'levelold', function () {
      switch (this.type) {
        case 'User':
          return 'Create';

        case 'Project access':
          return 'Make ' + this.roles[this.levelnew];

        case 'Group membership':
          if (this.levelnew) {
            return 'Make member';
          } else {
            return 'Remove member';
          }

        case 'Group':
          if (this.levelnew == 20 && this.levelold == 10) {
            return 'Make AD controlled';
          } else if (this.levelnew) {
            return 'Create';
          } else {
            return 'Remove';
          }

        default:
          return 'Other';
      }
    }),
    projectName: Ember.computed('project', function () {
      if (this.project) {
        var _this$store$peekRecor;

        return ((_this$store$peekRecor = this.store.peekRecord('project', this.project + '')) === null || _this$store$peekRecor === void 0 ? void 0 : _this$store$peekRecor.name) || this.project;
      } else {
        return '';
      }
    }),
    noop: Ember.computed('levelold', 'levelnew', function () {
      return this.levelold == this.levelnew;
    }),
    changeNeeded: Ember.computed('noop', function () {
      if (this.noop) {
        return 'NO';
      } else {
        return 'YES';
      }
    }),
    redundant: Ember.computed('gateway', 'project', function () {
      return this.gateway && this.project;
    }),
    style: Ember.computed('noop', 'levelnew', function () {
      if (this.noop) {
        return '';
      } else {
        if (this.levelnew && this.levelold) {
          return 'ad-import--change';
        } else if (this.levelnew) {
          return 'ad-import--add';
        } else {
          return 'ad-import--remove';
        }
      }
    })
  });

  _exports.default = _default;
});