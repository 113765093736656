define("mgw/components/dashboard-grid", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = window.$;
  var Slick = window.Slick;
  var columnFilters = {};

  var lowerCaseMismatch = function lowerCaseMismatch(itemValue, filterValue) {
    return itemValue.toLowerCase().indexOf(filterValue.toLowerCase()) == -1;
  };

  var isMatch = function isMatch(itemValue, filterValue) {
    if (itemValue == null) {
      return false;
    } else if (filterValue && typeof itemValue === "string" && lowerCaseMismatch(itemValue, filterValue)) {
      return false;
    } else if (typeof itemValue === "number" && itemValue != parseInt(filterValue)) {
      return false;
    } else if (typeof itemValue === "boolean" && (itemValue + "").indexOf(filterValue) == -1) {
      return false;
    }

    return true;
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    tagName: "ul",
    // input
    items: null,
    columns: [],
    cache: null,
    columnSettingsKey: null,
    columnSettingsVersion: null,
    sortByCol: Ember.computed("cache", function () {
      var storedColumn = this.get('settings.' + this.columnSettingsKey + 'SortByCol');
      return storedColumn ? storedColumn : {
        field: 'id'
      };
    }),
    sortIsAsc: Ember.computed("cache", function () {
      var sortIsAsc = this.get('settings.' + this.columnSettingsKey + 'SortIsAsc');
      return sortIsAsc ? true : false;
    }),
    data: Ember.computed("sortedItems.[]", function () {
      var data = [];
      this.sortedItems.forEach(function (item) {
        data.push({
          id: item.get("id"),
          record: item
        });
      });
      return data;
    }),
    sortedItems: Ember.computed("items", "sortByCol", "sortIsAsc", "cache", function () {
      var _this = this;

      var sort = this.items.sortBy(this.sortByCol.field);

      if (this.sortByCol.field == "FEATURE") {
        return this.items.toArray().sort(function (a, b) {
          var aContains = a.get("features").isAny("id", _this.sortByCol.id);
          var bContains = b.get("features").isAny("id", _this.sortByCol.id);
          var ret = (aContains - bContains) * (_this.sortIsAsc ? -1 : 1);

          if (ret !== 0) {
            return ret;
          }

          return Ember.compare(a.get("id"), b.get("id"));
        });
      } else if (this.sortByCol.field == "id") {
        sort = this.items.toArray().sort(function (a, b) {
          return Ember.compare(parseInt(a.get("id")), parseInt(b.get("id")));
        });
      }

      if (this.sortIsAsc) return sort;
      return sort.reverse();
    }),
    observeCache: Ember.observer("cache", function () {
      this.refreshGrid();
    }),
    dataView: null,
    grid: null,
    showModal: false,
    selectedProject: null,
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
      window.enableJqueryDrag();
    },
    defaultFormatter: function defaultFormatter(row, cell, value, columnDef, dataContext) {
      return dataContext.record.get(columnDef.field);
    },
    filterFunc: function filterFunc(item, columnId) {
      if (columnId !== undefined && columnFilters[columnId] !== "") {
        var c = this.grid.getColumns()[this.grid.getColumnIndex(columnId)];
        var itemValue = item.record.get(c.field);
        var filterValue = columnFilters[columnId];

        if (!isMatch(itemValue, filterValue)) {
          return false;
        }
      }

      return true;
    },
    defaultFilter: function defaultFilter(item) {
      for (var columnId in columnFilters) {
        if (!this.filterFunc(item, columnId)) {
          return false;
        }
      }

      if (this.columnSettingsKey == "projectColumns") {
        var hideDeletedIsChecked = this.get("grid").getColumns()[this.get("grid").getColumnIndex('project.deletedAt')].header.buttons[0].checked;

        if (item.record.get('project.deletedAt') && hideDeletedIsChecked) {
          return false;
        }

        var hideHibernatedIsChecked = this.get("grid").getColumns()[this.get("grid").getColumnIndex('project.isHibernated')].header.buttons[0].checked;

        if (item.record.get('project.isHibernated') && hideHibernatedIsChecked) {
          return false;
        }
      } else if (this.columnSettingsKey == "dashboardProjectColumns") {
        if (item.record.get('project.deletedAt')) {
          return false;
        }
      }

      return true;
    },
    refreshGrid: function refreshGrid() {
      this.dataView.beginUpdate();
      this.dataView.setItems(this.data.toArray());
      this.dataView.endUpdate();
      this.grid.invalidateAllRows();
      this.grid.render();
    },
    saveColumnSettings: function saveColumnSettings() {
      this.set('settings.' + this.columnSettingsKey, this.get("grid").getColumns());
      this.set('settings.' + this.columnSettingsKey + 'Version', this.columnSettingsVersion);
    },
    setup: function setup() {
      var _this2 = this;

      var self = this;
      var options = {
        enableCellNavigation: true,
        showHeaderRow: true,
        explicitInitialization: true,
        enableColumnReorder: true,
        // requires jqery.ui
        defaultFormatter: this.defaultFormatter,
        headerRowHeight: 34,
        forceSyncScrolling: true,
        defaultColumnWidth: 100,
        enableAsyncPostRender: true,
        asyncPostRenderDelay: 0
      };
      this.set("dataView", new Slick.Data.DataView());
      this.set("grid", new Slick.Grid("#".concat(this.elementId, " .slick-container"), this.dataView, this.columns, options));
      this.grid.setSortColumn(this.sortByCol.field, this.sortIsAsc);
      this.grid.onCellChange.subscribe(function (e, args) {
        _this2.dataView.updateItem(args.item.id, args.item);
      });
      this.dataView.onRowCountChanged.subscribe(function () {
        _this2.grid.updateRowCount();

        _this2.grid.render();
      });
      this.dataView.onRowsChanged.subscribe(function (e, args) {
        _this2.grid.invalidateRows(args.rows);

        _this2.grid.render();
      });
      this.grid.onColumnsResized.subscribe(function () {
        _this2.saveColumnSettings();
      });
      this.grid.onColumnsReordered.subscribe(function () {
        _this2.saveColumnSettings();
      });
      $(this.grid.getHeaderRow()).on("change keyup", ":input", function () {
        var columnId = $(this).data("columnId");

        if (columnId != null) {
          columnFilters[columnId] = $.trim($(this).val());
          self.get("dataView").refresh();
        }
      });
      this.grid.onHeaderRowCellRendered.subscribe(function (e, args) {
        $(args.node).empty();
        $("<input type='text'>").data("columnId", args.column.id).val(columnFilters[args.column.id]).appendTo(args.node);
      });
      this.grid.onSort.subscribe(function (e, args) {
        _this2.set('settings.' + _this2.columnSettingsKey + 'SortByCol', args.sortCol);

        _this2.set('settings.' + _this2.columnSettingsKey + 'SortIsAsc', args.sortAsc);

        _this2.set('cache', new Date());
      });
      this.grid.onClick.subscribe(function (e) {
        if (e.target.classList.contains("edit-item")) {
          var id = $(event.target).attr("data-id");
          self.editItem(id);
        }
      });
      this.grid.init();
      this.dataView.beginUpdate();
      this.dataView.setItems(this.data.toArray());
      this.dataView.setFilter(this.defaultFilter.bind(this));
      this.dataView.endUpdate();
      this.grid.resizeCanvas();
      var headerButtonsPlugin = new Slick.Plugins.HeaderButtons();
      headerButtonsPlugin.onCommand.subscribe(function (e, args) {
        e.stopPropagation();

        if (args.command == "toggleHideDeleted") {
          args.button.checked = !args.button.checked;

          _this2.saveColumnSettings();
        }

        if (args.command == "toggleHideHibernated") {
          args.button.checked = !args.button.checked;

          _this2.saveColumnSettings();
        }

        _this2.grid.invalidate();

        self.dataView.refresh();
      });
      this.grid.registerPlugin(headerButtonsPlugin);
    },
    willDestroyElement: function willDestroyElement() {
      window.disableJqueryDrag();
      this.grid.destroy();
      this.set("grid", null);
      this.set("dataView", null);
    }
  });

  _exports.default = _default;
});