define("mgw/services/eventservice", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intercom: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    pinpointClient: null,
    sessionRunning: false,
    disabled: _environment.default.environment == 'on-premise',
    disableIntercom: Ember.computed('session.accessToken', function () {
      var _this$get;

      // Yes this is a hack
      return (_this$get = this.get('session.accessToken')) === null || _this$get === void 0 ? void 0 : _this$get.includes('FAGBOKFORLAGET');
    }),
    startSession: function startSession(userId) {
      if (this.disabled) {
        return;
      }

      this.set('sessionRunning', true);

      try {
        this._setupPinpoint(userId);

        this.pinpointClient.startSession();
      } catch (e) {
        console.error('Could not start Pinpoint session', e);
      }

      if (!this.disableIntercom) {
        try {
          this.intercom.boot({
            app_id: _environment.default.intercom.appId,
            email: userId,
            language_override: this.intl.locale[0]
          });
        } catch (e) {
          console.error('Could not start Intercom session', e);
        }
      }
    },
    sendEvent: function sendEvent(eventName) {
      var _this = this;

      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var receipients = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "pic";
      var logLevel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "DEBUG";

      if (this.disabled) {
        return;
      }

      try {
        this._extendAttributesWithGlobalAttributes(attributes).then(function () {
          if (receipients.includes('p')) _this._sendPinpointEvent(eventName, attributes);
          if (receipients.includes('c')) _this._sendCentralLogEvent(eventName, attributes, logLevel);
          if (receipients.includes('i') && !_this.disableIntercom) _this._sendIntercomEvent(eventName, attributes);
          if (receipients.includes('g')) _this._sendGoogleAnalyticsEvent(eventName);
        });
      } catch (e) {
        console.error('Error when sending event', e);
      }
    },
    submitEvents: function submitEvents() {
      if (this.disabled) {
        return;
      }

      if (this.pinpointClient) {
        this.pinpointClient.submitEvents();
      }
    },
    updateState: function updateState() {
      if (this.disabled) {
        return;
      }

      if (!this.disableIntercom && this.intercom.isBooted) {
        this.intercom.update();
      }
    },
    stopSession: function stopSession() {
      if (this.disabled) {
        return;
      }

      this.set('sessionRunning', false);

      if (this.pinpointClient) {
        this.pinpointClient.submitEvents();
        this.pinpointClient.stopSession();
      }

      if (!this.disableIntercom && this.intercom.isBooted) {
        this.intercom.shutdown();
      }
    },
    _sendPinpointEvent: function _sendPinpointEvent(eventName, attributes) {
      if (this.pinpointClient) {
        this.pinpointClient.recordEvent(eventName, attributes);
      }
    },
    _sendCentralLogEvent: function _sendCentralLogEvent(eventName, attributes, logLevel) {
      window.sendCentralLog({
        event: eventName,
        logLevel: logLevel,
        message: JSON.stringify(attributes)
      });
    },
    _sendIntercomEvent: function _sendIntercomEvent(eventName, attributes) {
      if (!this.disableIntercom && this.intercom.isBooted) {
        // sending more attributes than 5 to intercom is rejected
        if (Object.keys(attributes).length > 5) attributes = {
          attributes: JSON.stringify(attributes)
        };
        this.intercom.trackEvent(eventName, attributes);
      }
    },
    _sendGoogleAnalyticsEvent: function _sendGoogleAnalyticsEvent(eventName) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Pageview',
        'url': '/_' + eventName.split('-').join('')
      });
    },
    _extendAttributesWithGlobalAttributes: function _extendAttributesWithGlobalAttributes(attributes) {
      return window.getFingerprint().then(function (fingerprint) {
        attributes.device_fingerprint = fingerprint;
      });
    },
    _setupPinpoint: function _setupPinpoint(userId) {
      var userAgent = window.navigator.userAgent.toLowerCase();
      var appVersion = userAgent.substring(userAgent.indexOf('cordova-') + 8);
      window.AWS.config.region = _environment.default.pinpoint.awsRegion;
      window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
        IdentityPoolId: _environment.default.pinpoint.identityPoolId
      });
      var options = {
        appId: _environment.default.pinpoint.appId,
        appTitle: _environment.default.pinpoint.appTitle,
        appVersionName: appVersion,
        make: window.device && window.device.manufacturer,
        model: window.device && window.devicemodel || window.bowser.name,
        platformVersion: window.device && window.device.version || window.bowser.version,
        locale: navigator.languages && navigator.languages[0] || navigator.language || navigator.userLanguage,
        globalAttribues: {
          userId: userId
        }
      };

      if (window.cordova && window.cordova.platformId) {
        options.platform = window.cordova.platformId;
      } else if (window.bowser.windows) {
        options.platform = 'windows';
      } else if (window.bowser.mac) {
        options.platform = 'mac';
      } else if (window.bowser.linux) {
        options.platform = 'linux';
      } else {
        options.platform = 'other';
      }

      this.set('pinpointClient', new window.AMA.Manager(options));
    }
  });

  _exports.default = _default;
});