define("mgw/components/select-item", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = window.$;

  var _default = _popoverBase.default.extend({
    actions: {
      upload: function upload(files, uploadAction) {
        this.get('delegate').send(this.get('callback'), uploadAction, null, files);
        this.send('close');
      },
      submit: function submit(value, context, selected, isUploadAction) {
        if (!value) return;

        if (isUploadAction) {
          $('#file-input-hidden').click();
          return;
        }

        if (!selected) this.get('delegate').send(this.get('callback'), value, context);
        this.send('close');
      }
    }
  });

  _exports.default = _default;
});