define("mgw/instance-initializers/centrallog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  window.getFingerprint = function () {
    return new Ember.RSVP.Promise(function (resolve) {
      if (window.deviceFingerprint) return resolve(window.deviceFingerprint);

      try {
        window.Fingerprint2.get({
          excludeWebGL: true
        }, function (components) {
          var values = components.map(function (component) {
            return component.value;
          });
          var result = window.Fingerprint2.x64hash128(values.join(''), 31);
          window.deviceFingerprint = result;
          resolve(result);
        });
      } catch (e) {
        console.warn('Could not fetch device fingerprint', e);
        resolve('unknown');
      }
    });
  };

  var _default = {
    name: 'centrallog',
    after: 'lasturl',
    initialize: function initialize(container) {
      window.sendCentralLog = function (_entry) {
        _entry = _entry || {}; // {
        //     Event     string `json:"event"`
        //     Route     string `json:"route"`
        //     LogLevel  string `json:"logLevel"` // INFO, WARNING, ERROR
        //     Message   string `json:"message"`
        //     UserID    string `json:"userId"`
        //     ProjectID string `json:"projectId"` // Project number
        // }

        window.getFingerprint().then(function () {
          var entry = {
            event: _entry.event || 'Debug',
            route: window.location.href,
            logLevel: _entry.logLevel || 'INFO',
            deviceFingerprint: window.deviceFingerprint
          };
          entry.message = entry.event;

          if (_entry.message) {
            entry.message = entry.message + ': ' + JSON.stringify(_entry.message).replace(/\\/g, '');
          }

          return new Ember.RSVP.Promise(function (resolve) {
            container.lookup('service:ajax').post('/mgw/api/v2/client/log', {
              data: entry
            }).then(function (response) {
              resolve(response);
            }, function (err) {
              console.debug('Could not send error log: ', err);
            });
          });
        });
      };

      window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
        var metadata = errorMsg || '';

        try {
          var stack = null;
          stack = errorObj && errorObj.stack && errorObj.stack.toString().substring(0, 500);
          metadata = {
            message: errorMsg,
            url: window.location.href + ' (' + url + ')',
            position: lineNumber + ', ' + column,
            stack: stack,
            deviceFingerprint: window.deviceFingerprint
          };
          window.sendCentralLog({
            event: 'JS error',
            logLevel: 'ERROR',
            message: JSON.stringify(metadata)
          });
        } catch (e) {
          console.debug('Could not send central log entry', e);
        }

        return false; // Preserves default behavior
      };
    }
  };
  _exports.default = _default;
});