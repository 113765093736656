define("mgw/authenticators/cognito-federation-existing", ["exports", "mgw/authenticators/cognito-federation"], function (_exports, _cognitoFederation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cognitoFederation.default.extend({
    authenticate: function authenticate(token, provider) {
      return Ember.RSVP.Promise.resolve({
        idToken: token,
        expiresIn: 3600,
        tokenType: 'Bearer',
        usernamePending: true,
        // Adding this so we know that 
        // this authenticator can not be 
        // used to fetch user profile yet
        provider: provider
      });
    }
  });

  _exports.default = _default;
});