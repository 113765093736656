define("mgw/templates/delete-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OMCjBNis",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mgw__content align-items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mgw__logo logo icon--delete\"],[12],[13],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"mgw__header mgw__subheader\"],[12],[1,[30,[36,0],[\"deleteUser\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"text-align-center margin-bottom-11\"],[12],[1,[30,[36,0],[\"deleteUser__description\"],null]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,1],[[32,0],\"contactSupport\"],null],[12],[1,[30,[36,0],[\"contactSupport\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "mgw/templates/delete-user.hbs"
    }
  });

  _exports.default = _default;
});