define("mgw/controllers/customers/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    customersController: Ember.inject.controller('customers'),
    staffUsers: Ember.computed.alias('customersController.model.staffUsers'),
    store: Ember.inject.service(),
    actions: {
      close: function close() {
        this.transitionToRoute('customers');
        this.set('customersController.cache', new Date());
      }
    }
  });

  _exports.default = _default;
});