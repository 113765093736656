define("mgw/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Would be tempting to use UnauthenticatedRouteMixin here, but that would
  // ignore query params I guess.
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    failedToAutoLogin: false,
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.to.queryParams || {};
      var userEmail = this.get('currentUser.user.email'); // Already logged in as this user? Just go to index.

      if (this.get('session.isAuthenticated') && params.email === userEmail) {
        return this.transitionAfterLogin(params);
      } // Session authenticated, but no email provided?


      if (this.get('session.isAuthenticated') && !params.email) {
        return this.transitionAfterLogin(params);
      }

      var promise = Ember.RSVP.resolve(); // Already logged in, but as a different user? Log out by dropping the session.

      if (this.get('session.isAuthenticated') && params.email) {
        promise = promise.then(function () {
          _this.set('session.dontReloadOnInvalidation', true);

          _this.session.invalidate();
        });
      }

      if (params.email) {
        this.set('currentUser.emailCandidateForLogin', params.email);
      } // If not logged in, try to (But if the temporary password doesn't work, then that's fine too)


      if (params.email && params.tmppass) {
        promise = promise.then(function () {
          return _this.tryAutoLogin(params.email, params.tmppass, params);
        });
      } // If not logged in, but we have the email and not tmppass, we can assume that you have been invited to a project


      if (params.email && !params.tmppass) {
        this.set('failedToAutoLogin', true);
      }

      return promise;
    },
    tryAutoLogin: function tryAutoLogin(email, password, params) {
      var _this2 = this;

      console.info("Trying to log in as ".concat(email));
      return this.session.authenticate('authenticator:cognito', {
        username: email,
        password: password
      }).then(function () {
        return _this2.transitionAfterLogin(params);
      }, function (err) {
        _this2.set('failedToAutoLogin', true);

        if (err.code !== 'invalid_grant' && err.description) {
          alert(err.description);
        } else {
          console.info("Failed to log in as ".concat(email, ", so will ask the user to login"));
        }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('failedToAutoLogin', this.failedToAutoLogin);
    },
    transitionAfterLogin: function transitionAfterLogin(params) {
      var filteredParams = {};

      if (params.next) {
        filteredParams['next'] = params.next;
      } // Here, Ember seems to ignore our query params for some absurd reason.
      // Maybe because we do this in the beforeModel?


      return this.transitionTo('index', {
        queryParams: filteredParams
      });
    }
  });

  _exports.default = _default;
});