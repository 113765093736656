define("mgw/components/workflow-chart-widget", ["exports", "mgw/mixins/widget-content"], function (_exports, _widgetContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widgetContent.default, {
    ajax: Ember.inject.service(),
    trendDays: 365,
    workflows: [],
    data: null,
    workflowOptions: Ember.computed("workflows.[]", function () {
      var res = [];

      for (var i = 0; i < this.get('workflows.length'); i++) {
        var workflow = this.get('workflows').objectAt(i);
        res.push({
          value: workflow.id,
          name: workflow.name
        });
      }

      return res;
    }),
    workflowsMap: Ember.computed("workflows.[]", function () {
      var res = {};

      for (var i = 0; i < this.get('workflows.length'); i++) {
        var workflow = this.get('workflows').objectAt(i);
        res[workflow.id] = workflow;
      }

      return res;
    }),
    workflowSelectionState: null,
    selectedWorkflow: Ember.computed("config.dataIds.[]", "workflowsMap", function () {
      if (!this.get('config.dataIds') || !this.get('workflowsMap')) {
        return null;
      }

      return this.get('workflowsMap')[this.get('config.dataIds')[0]];
    }),
    observeSelectedWorkflow: Ember.observer("selectedWorkflow", function () {
      if (this.get('selectedWorkflow') !== null) {
        this.set('workflowSelectionState', this.get('selectedWorkflow').id);
      }
    }),
    trendSeries: [],
    trendLabels: Ember.computed('trendSeries', function () {
      var labels = [];
      var millisecondsInDay = 1000 * 60 * 60 * 24;
      var firstDate = new Date().getTime() - millisecondsInDay * (this.get('trendDays') - 1);

      for (var i = 0; i < this.get('trendDays'); i++) {
        labels.push(window.moment(firstDate + millisecondsInDay * i).format('YYYY-MM-DD'));
      }

      return labels;
    }),
    didEnterViewport: function didEnterViewport() {
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;

      this.store.query('workflow', {
        projectId: this.get('project.id')
      }).then(function (workflows) {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
          _this.set('workflows', workflows);

          if (_this.get('selectedWorkflow') === null) {
            // This happens just after creating the widget, we assign the first workflow by default
            _this.set('isEditing', true);

            _this.set('workflowSelectionState', _this.get('workflows').objectAt(0).id);
          } else {
            _this.loadWorkflowData();
          }
        }
      });
      this.get('selectedWorkflow'); // Forcing computed property
    },
    loadWorkflowData: function loadWorkflowData() {
      var _this2 = this;

      this.set('isDataReady', false);
      var url = 'project-' + this.get('project.id') + '/api/v1/topics/trends';
      this.ajax.post(url, {
        data: {
          days: 365,
          selectors: {
            isDeleted: false,
            workflow: this.get('selectedWorkflow').id
          }
        }
      }).then(function (resp) {
        var series = [resp.closed, resp.open];

        _this2.set('trendSeries', series);

        _this2.set('isDataReady', true);
      }).catch(function (err) {
        console.log("Error getting workflow chart data " + err.payload.error);
      });
    },
    actions: {
      applySettings: function applySettings() {
        var _this3 = this;

        this._super();

        this.set('config.dataIds', [this.get('workflowSelectionState')]);
        this.get('config').save().then(function () {
          return _this3.loadWorkflowData();
        });
      },
      cancelEditing: function cancelEditing() {
        this._super();

        if (!this.get('selectedWorkflow')) {
          // This happens when we cancel just after creating the widget without
          // selecting any workflow, so we just delete it
          this.deleteWidget(this.get('config'));
        }
      },
      changeWorkflow: function changeWorkflow(selected) {
        this.set('workflowSelectionState', selected.value);
      }
    }
  });

  _exports.default = _default;
});