define("mgw/models/checklist", ["exports", "ember-data", "mgw/models/model", "mgw/mixins/with-snapshot-data"], function (_exports, _emberData, _model, _withSnapshotData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_withSnapshotData.default, {
    name: _emberData.default.attr('string'),
    fieldForTitle: _emberData.default.attr('string', {
      defaultValue: 'Name'
    }),
    groupBy: _emberData.default.attr('string'),
    isDraft: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    //ifcQuery: DS.belongsTo('ifcQuery'),
    workflow: _emberData.default.belongsTo('workflow'),
    workflowPrefix: _emberData.default.attr('string'),
    //checklistItems: DS.hasMany('checklistItem', {async: true}),
    copyChecklist: _emberData.default.belongsTo('checklist', {
      inverse: null
    }),
    //buildings: DS.hasMany('building', {async: true, inverse: null}),
    useWhitelist: _emberData.default.attr('boolean'),
    //whitelistObjects: DS.hasMany('ifcLightObject', {async: true, inverse: null}),
    //blacklistObjects: DS.hasMany('ifcLightObject', {async: true, inverse: null}),
    // ---- For repeating -----
    startDate: _emberData.default.attr('date'),
    endDate: _emberData.default.attr('date'),
    repeatEvery: _emberData.default.attr('string'),
    // null, 'day', 'week', 'month', 'year'
    interval: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    // For week option    
    daysOfWeek: _emberData.default.attr(),
    // [1, 2] = every Monday and Tuesday
    // For month option   
    daysInMonth: _emberData.default.attr(),
    // [1, 2] = every 1st and 2nd of every month
    //# For year option   
    monthsInYear: _emberData.default.attr(),
    // [1, 2] = January and February
    editors: Ember.computed.alias('workflow.editors'),
    canSeeChecklistStatus: Ember.computed.alias('workflow.generalAccess'),
    canFillOutChecklist: Ember.computed.alias('workflow.canBeAssigned'),
    canSignChecklist: Ember.computed.alias('workflow.canClose'),
    permissionKeys: ['canSeeChecklistStatus', 'canFillOutChecklist'],
    isOnProjectOrBuilding: Ember.computed('groupBy', function () {
      return this.get('groupBy') === 'project' || this.get('groupBy') === 'building';
    }),
    filterByBuilding: Ember.computed('isOnProjectOrBuilding', function () {
      return !this.get('isOnProjectOrBuilding');
    }),
    lastNameSegment: Ember.computed('name', function () {
      return this.get('name').split('/').pop();
    }),
    singleInstanceMode: Ember.computed('groupBy', function () {
      return this.get('groupBy') === 'project';
    })
    /*onZones: Ember.computed 'ifcQuery.rules.[]', ->
      @get('ifcQuery.rules')?.find( (_andRules) =>
        _andRules.find( (rule) -> rule.propKey is '@kind' and rule.propValue is 'Spatial zone')
      )*/

  });

  _exports.default = _default;
});