define("mgw/instance-initializers/cordova", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var userAgent = window.navigator.userAgent;

  function onDeviceReady() {
    console.info('Device ready');

    if (window.cordova && window.cordova.InAppBrowser) {
      window.open = window.cordova.InAppBrowser.open;
    }

    window.navigator.splashscreen && window.navigator.splashscreen.hide();

    if (window.cordova.plugins && window.cordova.plugins.Keyboard) {
      window.cordova.plugins.Keyboard.hideKeyboardAccessoryBar(false);
    }

    function keyboardShowHandler() {
      (0, _jquery.default)('#launcher').hide();
    }

    function keyboardHideHandler() {
      (0, _jquery.default)('#launcher').show();
    }

    window.addEventListener('native.keyboardshow', keyboardShowHandler);
    window.addEventListener('native.keyboardhide', keyboardHideHandler);

    if (window.PushNotification) {
      try {
        var push = window.PushNotification.init({
          android: {},
          ios: {
            sound: true,
            vibration: true,
            badge: true
          }
        });
        push.on('notification', function (data) {
          if (data.additionalData && data.additionalData.deepLink) {
            console.debug('Received push notification', data); // The below is based on answers from
            // https://github.com/phonegap/phonegap-plugin-push/issues/2094
            // https://github.com/phonegap/phonegap-plugin-push/issues/1230

            var notificationWasClicked = data.additionalData.foreground === false && (data.additionalData.dismissed === false || data.additionalData.coldstart === true || window.bowser.ios || window.bowser.safari);

            if (notificationWasClicked) {
              window.location.href = data.additionalData.deepLink;
              Ember.run.next(function () {
                window.location.reload();
              });
            }
          }
        });
      } catch (e) {
        console.error('Error when adding listener for push notifications', e);
      }
    }
  }

  var _default = {
    name: 'cordova',
    after: 'current-user',
    initialize: function initialize() {
      if (/cordova/.test(userAgent)) {
        var platform = window.bowser.android ? 'android' : 'ios';
        var appVersion = userAgent.substring(userAgent.indexOf('cordova-') + 8);
        var cordovaPrefix = platform + '-' + appVersion;

        var displayCordovaFailedMessage = function displayCordovaFailedMessage() {
          if (['local', 'development', 'staging'].includes(Ember.ENV.environment)) {
            alert('Could load cordova plugins ' + cordovaPrefix);
          }
        };

        Ember.RSVP.all([_jquery.default.getScript(cordovaPrefix + '/cordova.js'), _jquery.default.getScript(cordovaPrefix + '/cordova_index.js')]).then(function () {
          if (window.cordova) {
            console.debug('Cordova loaded OK');
          } else {
            displayCordovaFailedMessage();
          }
        }).catch(function (err) {
          console.warn('Cordova failed: ', err);
          displayCordovaFailedMessage();
        });
        document.addEventListener('deviceready', onDeviceReady, false);

        window.shouldRotateToOrientation = function () {
          return true;
        };
      }
    }
  };
  _exports.default = _default;
});