define("mgw/instance-initializers/disablejquerydrag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'disablejquerydrag',
    initialize: function initialize() {
      var jqueryDragEvents = ['draginit', 'dragstart', 'dragend', 'drag', 'drop'];
      var disabledEvents = {};

      var transferKeys = function transferKeys(keys, from, to) {
        keys.forEach(function (key) {
          if (from[key]) {
            to[key] = from[key];
            delete from[key];
          }
        });
      };

      window.disableJqueryDrag = function () {
        // Prevent jquery.event.drag from hijacking drag/keyboard events
        transferKeys(jqueryDragEvents, window.$.event.special, disabledEvents);
      };

      window.enableJqueryDrag = function () {
        // Re-apply jquery.event.drag hijacking of drag/keyboard events
        transferKeys(jqueryDragEvents, disabledEvents, window.$.event.special);
      };

      window.disableJqueryDrag();
    }
  };
  _exports.default = _default;
});