define("mgw/templates/components/popover-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QbPU68zi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"overlay overlay--popover \",[30,[36,3],[[35,7],\"overlay--transparent\"],null]]]],[24,\"role\",\"button\"],[4,[38,8],[[32,0],\"clickedOverlay\"],null],[12],[13],[2,\"\\n\\n\"],[6,[37,10],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[15,0,[31,[\"modal modal--centered \",[30,[36,3],[[35,5],\"height-100\"],null],\" \",[34,4]]]],[15,\"disabled\",[34,1]],[12],[2,\"\\n\\t\\t\\t\"],[18,1,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,1,\"popover\"],[15,0,[31,[\"popover \",[34,0]]]],[15,\"disabled\",[34,1]],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,1,\"popover-tap\"],[15,0,[31,[\"popover-tap \",[30,[36,3],[[35,2],\"hidden\"],null]]]],[12],[13],[2,\"\\n\\t\\t  \"],[18,1,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"popoverClass\",\"disabled\",\"hideTap\",\"if\",\"modalClass\",\"fullHeight\",\"centered\",\"transparent\",\"action\",\"draggable\",\"unless\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/popover-component.hbs"
    }
  });

  _exports.default = _default;
});