define("mgw/components/workflows-widget", ["exports", "mgw/mixins/widget-content"], function (_exports, _widgetContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_widgetContent.default, {
    workflowsSortKey: ['workflowName'],
    workflows: [],
    sortedWorkflows: Ember.computed.sort('workflows', 'workflowsSortKey'),
    workflowsMap: Ember.computed("workflows.[]", function () {
      var res = {};

      for (var i = 0; i < this.get('workflows.length'); i++) {
        var workflow = this.get('workflows').objectAt(i);
        res[workflow.id] = workflow;
      }

      return res;
    }),
    selectedWorkflows: Ember.computed("config.dataIds.[]", "workflowsMap", function () {
      var res = [];

      if (!this.get('config.dataIds') || !this.get('workflowsMap')) {
        return res;
      }

      var _iterator = _createForOfIteratorHelper(this.get('config.dataIds')),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var workflowId = _step.value;

          if (this.get('workflowsMap')[workflowId]) {
            res.push(this.get('workflowsMap')[workflowId]);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return res;
    }),
    sortedSelectedWorkflows: Ember.computed.sort('selectedWorkflows', 'workflowsSortKey'),
    observeSelectedWorkflows: Ember.on('init', Ember.observer("selectedWorkflows", function () {
      var res = {};

      var _iterator2 = _createForOfIteratorHelper(this.get('selectedWorkflows')),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var workflow = _step2.value;
          res[workflow.id] = true;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this.set('workflowSelectionState', res);
    })),
    workflowSelectionState: {},
    observeWorkflowSelectionState: Ember.observer('workflowSelectionState', function () {
      this.set('selectAllWorkflowsState', Object.keys(this.get('workflowSelectionState')).length === this.get('workflows').length);
    }),
    selectAllWorkflowsState: false,
    didEnterViewport: function didEnterViewport() {
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;

      this.set('isDataReady', false);
      this.store.query('workflow-status', {
        projectId: this.get('project.id')
      }).then(function (workflows) {
        if (!_this.isDestroyed) {
          _this.set('workflows', workflows);

          _this.set('isDataReady', true);

          if (_this.get('selectedWorkflows').length === 0) {
            _this.set('isEditing', true);
          }
        }
      });
    },
    actions: {
      applySettings: function applySettings() {
        this._super();

        var newSelectedWorkflows = [];
        var selectionState = this.get('workflowSelectionState');

        for (var selectedId in selectionState) {
          if (selectionState[selectedId]) {
            newSelectedWorkflows.push(selectedId);
          }
        }

        this.set('config.dataIds', newSelectedWorkflows);
        this.get('config').save();
      },
      changeWorkflowSelection: function changeWorkflowSelection(workflowId) {
        var selectionState = this.get('workflowSelectionState');

        if (selectionState[workflowId]) {
          delete selectionState[workflowId];
        } else {
          selectionState[workflowId] = true;
        }

        var newSelectionState = {};

        for (var selectedId in selectionState) {
          newSelectionState[selectedId] = true;
        }

        this.set('workflowSelectionState', newSelectionState);
      },
      changeAllWorkflowsSelection: function changeAllWorkflowsSelection() {
        if (!this.get('selectAllWorkflowsState')) {
          var res = {};

          for (var i = 0; i < this.get('workflows.length'); i++) {
            var workflow = this.get('workflows').objectAt(i);
            res[workflow.id] = true;
          }

          this.set('workflowSelectionState', res);
        } else {
          this.set('workflowSelectionState', {});
        }
      }
    }
  });

  _exports.default = _default;
});