define("mgw/components/resources-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var resourceTypesMap = {
    checklists: 'checklists',
    checklistId: 'checklists',
    checklistPrefix: 'checklists',
    workflows: 'workflows',
    workflowId: 'workflows',
    workflowPrefix: 'workflows',
    folders: 'folders',
    folderId: 'folders',
    groups: 'groups',
    groupId: 'groups',
    folderLabels: 'folderLabels',
    folderLabelId: 'folderLabels',
    folderLabelPrefix: 'folderLabels',
    reportTemplates: 'reportTemplates',
    reportTemplateId: 'reportTemplates',
    widgets: 'widgets',
    widgetId: 'widgets',
    taktTrains: 'taktTrains',
    taktTrainId: 'taktTrains'
  };
  var resourcesWithPrefixes = ['checklistId', 'workflowId', 'folderLabelId'];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['flex', 'row', 'height-100', 'width-100'],
    fromTemplate: false,
    selectedSection: null,
    selectedSectionResources: Ember.computed('selectedSection', 'resourcesMap', function () {
      var _this$get, _this$get$this$get;

      if (!this.get('selectedSection')) return [];
      return ((_this$get = this.get('resourcesMap')) === null || _this$get === void 0 ? void 0 : (_this$get$this$get = _this$get[this.get('selectedSection')]) === null || _this$get$this$get === void 0 ? void 0 : _this$get$this$get.resources) || [];
    }),
    resourcesMap: Ember.computed('resources.[]', 'fromTemplate', 'libraryColorMap', function () {
      var _this = this;

      if (!this.get('resources.length')) return {};
      var resourcesMap = {}; // First we create a dictionary with the different types of resources

      this.get('resources').forEach(function (resource) {
        var resourceType = resourceTypesMap[resource.key] || resource.key;

        if (!resourcesMap[resourceType]) {
          resourcesMap[resourceType] = {
            id: resourceType,
            name: _this.intl.t(resourceType) || resourceType,
            count: 0,
            resources: [],
            colors: []
          }; // We add for libraries a first option to select all items from same type,
          // except for when the first item is already the select-all option (case for configs)

          if (!_this.get('fromTemplate') && !resource.isEverything) {
            resourcesMap[resourceType].resources.push({
              data: {
                sourceProjectId: Number(resource.sourceProjectId),
                key: resourceType,
                renameTo: null,
                value: ""
              }
            });
          }
        }

        var color = null;

        if (_this.get('fromTemplate')) {
          var _this$get2;

          color = (_this$get2 = _this.get('libraryColorMap')) === null || _this$get2 === void 0 ? void 0 : _this$get2[resource.sourceProjectId];

          if (color && !resourcesMap[resourceType].colors.includes(color)) {
            resourcesMap[resourceType].colors.push(color);
          }
        }

        resourcesMap[resourceType].resources.push({
          color: color,
          data: resource
        });
      });

      var _loop = function _loop(key) {
        var element = resourcesMap[key];
        element.resources.sort(function (a, b) {
          // If any of the items have no value, that is the everything option, which we place at the top
          if (!a.data.value) return -1;
          if (!b.data.value) return 1; // If any of the items have no renameTo, that is a prefix, 
          // which we place before items, and order by value

          if (!a.data.renameTo && !b.data.renameTo) {
            if (a.data.value < b.data.value) return -1;
            if (a.data.value > b.data.value) return -1;
            return 0;
          }

          if (!a.data.renameTo) return -1;
          if (!b.data.renameTo) return 1; // Rest of the items we just order by name

          if (a.data.renameTo < b.data.renameTo) return -1;
          if (a.data.renameTo > b.data.renameTo) return 1;
          return 0;
        });
        var resourcesCount = 0; // For library resources we generate a tree structure and use it as resources

        if (!_this.get('fromTemplate')) {
          var resourcesPerType = element.resources;
          var nodes = [];
          resourcesPerType.forEach(function (resource) {
            nodes.push(Ember.Object.create({
              name: resource.data.renameTo,
              value: resource.data.key,
              record: resource,
              empty: false
            }));
          });
          var treeNodes = [];
          var foldersById = {};
          nodes.forEach(function (node) {
            var _node$name;

            var path = ((_node$name = node.name) === null || _node$name === void 0 ? void 0 : _node$name.split('/')) || [''];
            path.forEach(function (segment, i) {
              var itemId = path.slice(0, i + 1).join('/');
              if (foldersById[itemId]) return;
              var parent = foldersById[path.slice(0, i).join('/')];
              var indentLevel = parent ? parent.indentLevel + 1 : 0;
              var indentStyle = 'min-width: ' + indentLevel * 20 + 'px';
              var item = Ember.Object.create({
                id: itemId,
                name: segment,
                isExpanded: true,
                isVisible: true,
                indentStyle: indentStyle,
                indentLevel: indentLevel,
                children: []
              });

              if (segment === path[path.length - 1]) {
                if (node.record.data.key === 'folderId') {
                  foldersById[itemId] = item; // Intermediate folders are both prefix and item
                }

                item.set('option', node);
                resourcesCount++;
              } else {
                foldersById[itemId] = item;
                var recordKey = node.record.data.key; // If we have a prefix, we need to change the key, prefixes only are created for some resource types

                var isPrefix = resourcesWithPrefixes.includes(recordKey);
                item.set('option', Ember.Object.create({
                  name: itemId,
                  value: itemId,
                  record: {
                    // node.record,
                    color: null,
                    data: {
                      sourceProjectId: node.record.data.sourceProjectId,
                      key: isPrefix ? recordKey.replace('Id', 'Prefix') : recordKey,
                      value: isPrefix ? itemId : node.record.data.value,
                      renameTo: isPrefix ? null : node.record.data.renameTo
                    }
                  },
                  empty: false
                }));
              }

              if (parent) {
                parent.children.push(item);
                item.parent = parent;
              } else {
                treeNodes.push(item);
              }
            });
          });
          resourcesMap[key].resources = treeNodes;
        } else {
          resourcesCount = element.resources.length;
        }

        resourcesMap[key].count = resourcesCount;
      };

      for (var key in resourcesMap) {
        _loop(key);
      }

      return resourcesMap;
    }),
    didRender: function didRender() {
      var keys = Object.keys(this.get('resourcesMap'));

      if (keys.length && (!this.get('selectedSection') || !keys.includes(this.get('selectedSection')))) {
        this.set('selectedSection', keys[0]);
      }
    },
    actions: {
      selectSection: function selectSection(section) {
        this.set('selectedSection', section.id);
        this.set('selectedResources', []);
      },
      selectResource: function selectResource(resource) {
        if (this.get('fromTemplate')) {
          if (this.onResourceSelected) this.onResourceSelected(resource, this.get('selectedSection'));
          return;
        }

        if (this.get('selectedResources')) {
          if (this.get('selectedResources').includes(resource)) {
            this.get('selectedResources').removeObject(resource);
          } else {
            this.get('selectedResources').addObject(resource);
          }
        }
      },
      selectResourceNode: function selectResourceNode(node) {
        this.send('selectResource', node.get('option.record.data'));
      },
      removeResource: function removeResource(resource) {
        this.removeResource(resource);
      },
      toggleExpandNode: function toggleExpandNode(node) {
        node.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;
});