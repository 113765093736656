define("mgw/templates/components/image-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QnBETHra",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,14],null,[[\"config\",\"editModeActive\",\"isEditing\",\"editingWidget\",\"moveWidget\",\"deleteWidget\",\"isFirst\",\"isLast\",\"changeEditingWidget\"],[[35,13],[35,12],[35,0],[35,11],[35,10],[35,9],[35,8],[35,7],[30,[36,2],[[32,0],\"changeEditingWidget\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"swap--face face--front \",[30,[36,1],[[35,0],\"\",\"face--visible\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"image-background\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \\n  \"],[10,\"div\"],[15,0,[31,[\"swap--face face--back \",[30,[36,1],[[35,0],\"face--visible\"],null]]]],[12],[2,\"\\n\"],[6,[37,6],null,[[\"title\",\"sizeOptions\",\"selectedSize\",\"changeSize\",\"applySettings\",\"cancelEditing\"],[[30,[36,5],[\"image\"],null],[35,4],[35,3],[30,[36,2],[[32,0],\"changeSize\"],null],[30,[36,2],[[32,0],\"applySettings\"],null],[30,[36,2],[[32,0],\"cancelEditing\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isEditing\",\"if\",\"action\",\"sizeSelectionState\",\"sizeOptions\",\"t\",\"edit-widget\",\"isLast\",\"isFirst\",\"deleteWidget\",\"moveWidget\",\"editingWidget\",\"editModeActive\",\"config\",\"dashboard-widget\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/image-widget.hbs"
    }
  });

  _exports.default = _default;
});