define("mgw/templates/password-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xVghRjPS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mgw__content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mgw__content mgw__content--white\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[[35,3]],[[\"email\",\"message\"],[[35,2],[35,1]]]]],[13],[2,\"\\n    \"],[6,[37,4],null,[[\"class\",\"route\"],[\"btn mgw__form__btn--submit mgw__form__btn--standalone\",\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"ok\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"message\",\"email\",\"infoMessage\",\"link-to\"]}",
    "meta": {
      "moduleName": "mgw/templates/password-changed.hbs"
    }
  });

  _exports.default = _default;
});