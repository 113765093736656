define("mgw/routes/guest", ["exports", "mgw/routes/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _login.default.extend({
    renderTemplate: function renderTemplate() {
      this.render('login', {
        controller: 'login'
      });
    },
    tryAutoLogin: function tryAutoLogin(email, password, params) {
      var _this = this;

      // Use guest login endpoint instead
      console.info("Trying to log in as ".concat(email));
      return this.session.authenticate('authenticator:cognito-guest', {
        username: email,
        password: password
      }).then(function () {
        return _this.transitionAfterLogin(params);
      }, function (err) {
        _this.set('failedToAutoLogin', true);

        if (err.code !== 'invalid_grant' && err.description) {
          alert(err.description);
        } else {
          console.info("Failed to log in as ".concat(email, ", so will ask the user to login"));
        }
      });
    }
  });

  _exports.default = _default;
});