define("mgw/models/project", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr('string'),
    projectUri: _emberData.default.attr('string', {
      readOnly: true
    }),
    description: _emberData.default.attr('string'),
    externalId: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    deletedAt: _emberData.default.attr('date', {
      readOnly: true
    }),
    owner: _emberData.default.belongsTo('user'),
    features: _emberData.default.hasMany('feature', {
      async: true
    }),
    customer: _emberData.default.belongsTo('customer'),
    isInactive: _emberData.default.attr('boolean'),
    isHibernated: _emberData.default.attr('boolean'),
    isLibrary: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    templateProjectId: _emberData.default.attr('number'),
    projectTemplateId: _emberData.default.attr('number'),
    salesforceAccountId: _emberData.default.attr('string'),
    salesforceAccountName: _emberData.default.attr('string'),
    salesforceProjectId: _emberData.default.attr('string'),
    dashboardWidgets: _emberData.default.hasMany('dashboard-widget', {
      inverse: 'project',
      async: true
    }),
    stringForSearch: Ember.computed('name', 'description', 'externalId', function () {
      return [this.get('name'), this.get('description'), this.get('externalId')].compact().join(' ').toLowerCase();
    })
  });

  _exports.default = _default;
});