define("mgw/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: Ember.computed.alias('id'),
    givenName: _emberData.default.attr('string'),
    familyName: _emberData.default.attr('string'),
    organization: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    privilegeLevel: _emberData.default.attr('number'),
    displayName: _emberData.default.attr('string', {
      readOnly: true,
      serialize: false
    }),
    role: _emberData.default.attr('string')
  });

  _exports.default = _default;
});