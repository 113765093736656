define("mgw/components/chartist-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = window.$;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    aspectRatio: 'ct-major-twelfth',
    classNameBindings: [':ct-chart', 'aspectRatio'],
    chart: null,
    labels: null,
    series: null,
    timeInterval: 'firstInMonth',
    relativeTime: false,
    observeChartData: Ember.observer("labels", "series", function () {
      this.draw();
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.draw);
    },
    draw: function draw() {
      var self = this;
      var barElementSelector = '#' + this.get('elementId');
      var graphWidth = $(barElementSelector).width();
      var showEvery = parseInt(1 + self.get('labels.length') / (graphWidth / 3));
      var newChart = new window.Chartist.Bar(barElementSelector, {
        labels: this.get('labels'),
        series: this.get('series')
      }, {
        stackBars: this.get('series').mapBy('length').sort()[0],
        width: '100%',
        height: '100%',
        chartPadding: {
          top: 15,
          right: 15,
          bottom: 5,
          left: 10
        },
        axisX: {
          offset: 30,
          labelOffset: {
            x: -20,
            y: 0
          },
          labelInterpolationFnc: function labelInterpolationFnc(value) {
            var formattedValue = '' + value;

            if (self.get('relativeTime')) {
              formattedValue = window.moment(value, 'YYYY-MM-DD').fromNow();
            }

            if (self.get('timeInterval') === 'firstInMonth') {
              var firstDayInMonth = new Date(value).getDate() == 1;
              var month = new Date(value).getMonth();
              if (firstDayInMonth && month % showEvery == 0) return formattedValue;else return null;
            } else if (self.get('timeInterval') === 'progressive') {
              var sinceNow = window.moment().startOf('day').toDate() - window.moment(value).startOf('day').toDate();
              var days = Math.ceil(window.moment.duration(sinceNow).asDays());
              var oneWeek = days === 6;
              var twoWeeks = days === 13;
              var threeWeeks = days === 20;
              var months = new Date(value).getDate() == new Date().getDate() && window.moment.duration(sinceNow).asMonths() > 0.5;

              if (self.get('labels.length') < 7) {
                return self.intl.t('days', {
                  count: days
                });
              }

              if (oneWeek && self.get('labels.length') < 30) {
                return self.intl.t('days', {
                  count: 7
                });
              }

              if (twoWeeks && self.get('labels.length') < 60) {
                return self.intl.t('days', {
                  count: 14
                });
              }

              if (threeWeeks && self.get('labels.length') < 60) {
                return self.intl.t('days', {
                  count: 21
                });
              }

              var count = Math.round(window.moment.duration(sinceNow).asMonths());

              if (count % parseInt(showEvery) === 0 && months) {
                return self.intl.t('months', {
                  count: count
                });
              }

              return null;
            }
          }
        },
        axisY: {
          labelOffset: {
            x: 0,
            y: 15
          },
          labelInterpolationFnc: function labelInterpolationFnc(value) {
            if (value % 1 == 0) return value;else return null;
          }
        }
      }).on('draw', function (data) {
        var count = self.get('labels.length');

        if (data.type == 'bar') {
          data.element.attr({
            style: 'stroke-width: ' + ($(barElementSelector).innerWidth() - 160) / count + 'px;'
          });
        }
      });
      this.set('chart', newChart);
      var $chart = $(barElementSelector);
      var $toolTip = $chart.find('.tooltip');

      if ($toolTip.length === 0) {
        $toolTip = $chart.append('<div class="tooltip"></div>').find('.tooltip').hide();
      }

      $chart.on('mouseenter', '.ct-bar', function () {
        var $point = $(this);
        window.testObj = $(this);
        var index = $point.index();
        var date = self.get('labels')[index];

        if (self.get('relativeTime')) {
          var days = -window.moment.duration(new Date(date) - new Date()).asDays().toFixed() || 0;
          date = self.intl.t('days', {
            count: days
          }) || self.intl.t('days', {
            count: days
          });
        }

        var value = parseFloat($point.attr('ct:value')).toFixed();
        $toolTip.html(date + ': <br>' + value).show();
      });
      $chart.on('mouseleave', '.ct-bar', function () {
        $toolTip.hide();
      });
      $chart.on('mousemove', function (event) {
        $toolTip.css({
          left: (event.offsetX || event.originalEvent.layerX) - $toolTip.width() / 2 - 10,
          top: (event.offsetY || event.originalEvent.layerY) - $toolTip.height() - 40
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      $(this.get('chart')).off();
      if (this.get('chart')) this.get('chart').detach();
    }
  });

  _exports.default = _default;
});