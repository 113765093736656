define("mgw/models/dashboard-widget", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    typeName: _emberData.default.attr('string'),
    dataIds: _emberData.default.attr(),
    size: _emberData.default.attr(),
    order: _emberData.default.attr('number'),
    project: _emberData.default.belongsTo('project', {
      inverse: 'dashboardWidgets',
      async: true
    })
  });

  _exports.default = _default;
});