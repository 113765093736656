define("mgw/controllers/projects", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var dateFormatter = function dateFormatter(row, cell, value, columnDef, dataContext) {
    var date = dataContext.record.get(columnDef.id);
    return date && window.moment(date).format("YYYY-MM-DD HH:mm");
  };

  var asyncFormatCheckBox = function asyncFormatCheckBox(cell, row, dataContext, columnDef) {
    var hasFeature = dataContext.record.get("features").isAny("id", columnDef.id);

    if (hasFeature) {
      cell[0].querySelector('input[type="checkbox"]').setAttribute("checked", "checked");
    }
  };

  var formatCheckBox = function formatCheckBox() {
    return "<input type=\"checkbox\" disabled=\"true\" />";
  };

  var linkFormatter = function linkFormatter(uri, value) {
    return "<a href=\"".concat(uri, "\" target=\"_system\" class=\"underline\">").concat(value, "</a>");
  };

  var customerFormatter = function customerFormatter(row, cell, value, columnDef, dataContext) {
    var project = dataContext.record.get("project");

    if (project.get("customer.id")) {
      return linkFormatter("customers/customer/" + project.get("customer.id"), project.get("customer.name"));
    } else {
      return null;
    }
  };

  var projectIdFormatter = function projectIdFormatter(row, cell, value, columnDef, dataContext) {
    return linkFormatter("https://" + dataContext.record.get("project.projectUri"), dataContext.record.get("project.id"));
  };

  var projectNameFormatter = function projectNameFormatter(row, cell, value, columnDef, dataContext) {
    return Ember.Handlebars.Utils.escapeExpression(dataContext.record.get(columnDef.field));
  };

  var projectDescriptionFormatter = function projectDescriptionFormatter(row, cell, value, columnDef, dataContext) {
    return Ember.Handlebars.Utils.escapeExpression(dataContext.record.get(columnDef.field));
  };

  var projectExternalIdFormatter = function projectExternalIdFormatter(row, cell, value, columnDef, dataContext) {
    return Ember.Handlebars.Utils.escapeExpression(dataContext.record.get(columnDef.field));
  };

  var templateFormatter = function templateFormatter(row, cell, value, columnDef, dataContext) {
    var id = dataContext.record.get(columnDef.field);
    if (!id) return;
    var projectUri = dataContext.record.get("project.projectUri");
    var projectId = dataContext.record.get("project.id");
    var newProjectUri = "https://" + projectUri.replace(projectId, id);
    return linkFormatter(newProjectUri, id);
  };

  var expandProject = function expandProject(row, cell, value, columnDef, dataContext) {
    var id = dataContext.record.get("id");
    return "<button class=\"edit-item\" data-id=\"".concat(id, "\">Edit</button>");
  };

  var defaultColumns = [{
    id: "edit",
    field: "edit",
    name: "Edit",
    formatter: expandProject,
    width: 75
  }, {
    id: "id",
    field: "id",
    name: "ID",
    formatter: projectIdFormatter,
    width: 75,
    sortable: true
  }, {
    id: "project.name",
    field: "project.name",
    name: "Name",
    formatter: projectNameFormatter,
    width: 300,
    sortable: true
  }, {
    id: "project.externalId",
    field: "project.externalId",
    name: "External id",
    formatter: projectExternalIdFormatter,
    width: 100,
    sortable: true
  }, {
    id: "project.description",
    field: "project.description",
    name: "Description",
    formatter: projectDescriptionFormatter,
    width: 300,
    sortable: true
  }, {
    id: "project.customer.kam.id",
    field: "project.customer.kam.id",
    name: "Kam",
    width: 200,
    sortable: true
  }, {
    id: "project.customer.name",
    field: "project.customer.name",
    name: "Customer",
    formatter: customerFormatter,
    width: 200,
    sortable: true
  }, {
    id: "project.salesforceAccountName",
    field: "project.salesforceAccountName",
    name: "SF Account",
    width: 200,
    sortable: true
  }, {
    id: "project.owner.id",
    field: "project.owner.id",
    name: "Owner",
    width: 300,
    sortable: true
  }, {
    id: "project.createdAt",
    field: "project.createdAt",
    name: "CreatedAt",
    formatter: dateFormatter,
    width: 200,
    sortable: true
  }, {
    id: "project.deletedAt",
    field: "project.deletedAt",
    name: "DeletedAt",
    formatter: dateFormatter,
    width: 200,
    sortable: true,
    header: {
      buttons: [{
        tagName: "input",
        type: 'checkbox',
        checked: true,
        tooltip: "Hide?",
        command: "toggleHideDeleted"
      }]
    }
  }, {
    id: "project.status",
    field: "project.status",
    name: "Status",
    width: 200,
    sortable: true
  }, {
    id: "project.isHibernated",
    field: "project.isHibernated",
    name: "Hibernated",
    width: 200,
    sortable: true,
    header: {
      buttons: [{
        tagName: "input",
        type: 'checkbox',
        checked: true,
        tooltip: "Hide?",
        command: "toggleHideHibernated"
      }]
    }
  }, {
    id: "fromTemplate",
    field: "fromTemplateId",
    name: "FromTemplate",
    formatter: templateFormatter,
    sortable: true
  }, {
    id: "numBuildings",
    field: "numBuildings",
    name: "Buildings",
    sortable: true
  }, {
    id: "numUsers",
    field: "numUsers",
    name: "Users",
    sortable: true
  }, {
    id: "numGroups",
    field: "numGroups",
    name: "Groups",
    sortable: true
  }, {
    id: "numDocuments",
    field: "numDocuments",
    name: "Documents",
    sortable: true
  }, {
    id: "numFolders",
    field: "numFolders",
    name: "Folders",
    sortable: true
  }, {
    id: "numIfc",
    field: "numIfc",
    name: "Ifc",
    sortable: true
  }, {
    id: "numIfcModels",
    field: "numIfcModels",
    name: "IfcModels",
    sortable: true
  }, {
    id: "numTemplates",
    field: "numTemplates",
    name: "Templates",
    sortable: true
  }, {
    id: "numActualTopics",
    field: "numActualTopics",
    name: "Topics",
    sortable: true
  }, {
    id: "openTopics",
    field: "numOpenTopics",
    name: "OpenTopics",
    sortable: true
  }, {
    id: "doneTopics",
    field: "numDoneTopics",
    name: "DoneTopics",
    sortable: true
  }, {
    id: "closedTopics",
    field: "numClosedTopics",
    name: "ClosedTopics",
    sortable: true
  }, {
    id: "numChecklists",
    field: "numChecklists",
    name: "Checklists",
    sortable: true
  }, {
    id: "numSpaces",
    field: "numSpaces",
    name: "Spaces",
    sortable: true
  }, {
    id: "ifcFileSize",
    field: "ifcFileSize",
    name: "IFC File Size",
    sortable: true
  }, {
    id: "customFloorplans",
    field: "customFloorplans",
    name: "Custom floorplans",
    sortable: true
  }, {
    id: "publicTemplateOrder",
    field: "publicTemplateOrder",
    name: "PublicTemplateOrder",
    sortable: true
  }, {
    id: "error",
    field: "error",
    name: "Error",
    sortable: true
  }];

  var _default = Ember.Controller.extend({
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    cache: null,
    actions: {
      editItem: function editItem(id) {
        this.transitionToRoute("projects.project", id);
      },
      export: function _export() {
        var _this = this;

        var headers = ['id', 'name', 'externalId', 'description', 'customer', 'kam', 'owner', 'isInactive', 'isHibernated', 'createdAt', 'deletedAt', 'fromTemplate', 'users', 'groups', 'documents', 'folders', 'ifc', 'workflows', 'topics', 'openTopics', 'doneTopics', 'closedTopics'];
        var rows = [headers.join(';')];
        this.store.peekAll('project').forEach(function (project) {
          var _project$get;

          var stats = _this.store.peekRecord('projectStat', project.id);

          if (!stats) {
            console.log("Missing stats from project: " + project.id);
          }

          var columns = [project.get('id'), project.get('name'), project.get('externalId'), project.get('description'), project.get('customer.name'), project.get('customer.kam.id'), project.belongsTo('owner').id(), project.get('isInactive'), project.get('isHibernated'), project.get('createdAt').toISOString(), ((_project$get = project.get('deletedAt')) === null || _project$get === void 0 ? void 0 : _project$get.toISOString()) || '', stats && stats.get('fromTemplateId') || '', stats && stats.get('numUsers') || '', stats && stats.get('numGroups') || '', stats && stats.get('numDocuments') || '', stats && stats.get('numFolders') || '', stats && stats.get('numIfc') || '', stats && stats.get('numTemplates') || '', stats && stats.get('numActualTopics') || '', stats && stats.get('numOpenTopics') || '', stats && stats.get('numDoneTopics') || '', stats && stats.get('numClosedTopics') || ''];
          rows.push(columns.join(';'));
        });
        var content = rows.join('\n');
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
        element.setAttribute('download', 'Projects.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    },
    columnSettingsVersion: Ember.computed(function () {
      // Bump the version if features are added
      return 8 + '_' + this.get('model.features').mapBy('id');
    }),
    columns: Ember.computed(function () {
      var _this2 = this;

      var storedColumns = this.get('settings.projectColumns');

      if (storedColumns && this.columnSettingsVersion == this.get('settings.projectColumnsVersion')) {
        // we need to overwrite the formatter here since usersettings cannot store functions 
        storedColumns.forEach(function (column) {
          var result = defaultColumns.findBy('id', column.id);

          if (result) {
            column.formatter = result.formatter;
            column.asyncPostRender = result.asyncPostRender;
          } else if (_this2.get('model.features').findBy('id', column.id)) {
            // set checkbox formatters for feature columns
            column.formatter = formatCheckBox;
            column.asyncPostRender = asyncFormatCheckBox;
          }
        });
        return storedColumns;
      } else {
        this.set('settings.projectColumns', null);
        this.set('settings.projectColumnsSortByCol', null);
        var columns = [];
        columns.pushObjects(defaultColumns);
        this.get('model.features').forEach(function (feature, i) {
          columns.insertAt(9 + i, {
            id: feature.get('id'),
            field: "FEATURE",
            name: feature.get('id'),
            formatter: formatCheckBox,
            asyncPostRender: asyncFormatCheckBox,
            sortable: true
          });
        });
        return columns;
      }
    })
  });

  _exports.default = _default;
});