define("mgw/components/goto-library", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    actions: {
      submit: function submit() {
        window.open('https://' + this.get('data.projectUri'), '_blank').focus();
        this.send('close');
      },
      cancel: function cancel() {
        this.send('close');
      }
    }
  });

  _exports.default = _default;
});