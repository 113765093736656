define("mgw/templates/new-project/created", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0F6FYbJs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mgw__content mgw__content--white\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,1],[\"theProjectIsNowSuccessfullyCreated\"],[[\"projectName\"],[[35,0,[\"name\"]]]]]],[13],[2,\"\\n  \"],[10,\"a\"],[15,6,[31,[\"https://\",[34,0,[\"projectUri\"]]]]],[14,0,\"btn mgw__form__btn--submit mgw__form__btn--standalone\"],[12],[1,[30,[36,1],[\"goToYourNewProject\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"t\"]}",
    "meta": {
      "moduleName": "mgw/templates/new-project/created.hbs"
    }
  });

  _exports.default = _default;
});