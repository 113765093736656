define("mgw/models/user-profile", ["exports", "ember-data", "mgw/models/user"], function (_exports, _emberData, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend({
    mustUpdatePassword: _emberData.default.attr('boolean', {
      readOnly: true,
      serialize: false
    }),
    mustUpdateProfile: _emberData.default.attr('boolean', {
      readOnly: true,
      serialize: false
    })
  });

  _exports.default = _default;
});