define("mgw/routes/customers", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments); // To let the Auth mixin call its beforeModel()

    },
    model: function model() {
      var modelHash = {
        customers: this.store.findAll('customer'),
        projects: this.store.findAll('project'),
        staffUsers: this.store.query('user', {
          filter: {
            role: "rendraStaff"
          }
        })
      };
      return Ember.RSVP.hash(modelHash);
    }
  });

  _exports.default = _default;
});