define("mgw/templates/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DGRmdoCE",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"columnSettingsKey\",\"columnSettingsVersion\",\"items\",\"columns\",\"editItem\",\"cache\"],[\"customerColumns\",[35,4],[35,3,[\"customers\"]],[35,2],[30,[36,1],[[32,0],\"editItem\"],null],[35,0]]]]],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"fab\"],[24,\"title\",\"Create new customer\"],[4,[38,1],[[32,0],\"createNewCustomer\"],null],[12],[2,\"+\"],[13],[2,\"\\n\\n\"],[1,[30,[36,7],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"cache\",\"action\",\"columns\",\"model\",\"columnSettingsVersion\",\"dashboard-grid\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mgw/templates/customers.hbs"
    }
  });

  _exports.default = _default;
});