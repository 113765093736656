define("mgw/models/project-link", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr("string"),
    projectUri: _emberData.default.attr("string"),
    isHibernated: _emberData.default.attr("boolean"),
    isLibrary: _emberData.default.attr("boolean"),
    nonLibrary: Ember.computed.not('isLibrary'),
    nonHibernated: Ember.computed.not('isHibernated'),
    isRegular: Ember.computed.and('nonLibrary', 'nonHibernated'),
    description: _emberData.default.attr('string'),
    externalId: _emberData.default.attr('string'),
    stringForSearch: Ember.computed('name', 'description', 'externalId', function () {
      return [this.get('name'), this.get('description'), this.get('externalId')].compact().join(' ').toLowerCase();
    })
  });

  _exports.default = _default;
});