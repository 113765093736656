define("mgw/templates/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ww5HveUi",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"columnSettingsKey\",\"columnSettingsVersion\",\"items\",\"columns\",\"editItem\",\"cache\"],[\"projectColumns\",[35,4],[35,3,[\"items\"]],[35,2],[30,[36,1],[[32,0],\"editItem\"],null],[35,0]]]]],[2,\"\\n\"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n\"],[11,\"button\"],[24,0,\"export-btn\"],[4,[38,1],[[32,0],\"export\"],null],[12],[2,\"Export\"],[13]],\"hasEval\":false,\"upvars\":[\"cache\",\"action\",\"columns\",\"model\",\"columnSettingsVersion\",\"dashboard-grid\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "mgw/templates/projects.hbs"
    }
  });

  _exports.default = _default;
});