define("mgw/helpers/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;

  function dateFormat(params) {
    return params[0] && window.moment(params[0]).format('YYYY-MM-DD HH:mm');
  }

  var _default = Ember.Helper.helper(dateFormat);

  _exports.default = _default;
});