define("mgw/helpers/eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.eq = void 0;

  var eq = function eq(params) {
    return params[0] === params[1];
  };

  _exports.eq = eq;

  var _default = Ember.Helper.helper(eq);

  _exports.default = _default;
});