define("mgw/components/generic-info-widget", ["exports", "mgw/mixins/widget-content"], function (_exports, _widgetContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_widgetContent.default, {
    stats: null,
    features: null,
    didEnterViewport: function didEnterViewport() {
      this.loadData();
    },
    loadData: function loadData() {
      this.set('isDataReady', false);
      var features = this.store.peekAll('feature');
      this.set('features', features);
      var stats = this.store.peekRecord('projectStat', this.get('project.id'));
      this.set('stats', stats);
      this.set('isDataReady', true);
    },
    actions: {
      applySettings: function applySettings() {
        this._super();

        this.get('config').save();
      }
    }
  });

  _exports.default = _default;
});