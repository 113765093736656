define("mgw/controllers/customers", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var editItemFormatter = function editItemFormatter(row, cell, value, columnDef, dataContext) {
    var id = dataContext.record.get('id');
    return "<button class=\"edit-item\" data-id=\"".concat(id, "\">Edit</button>");
  };

  var dateFormatter = function dateFormatter(row, cell, value, columnDef, dataContext) {
    var date = dataContext.record.get(columnDef.id);
    return date && window.moment(date).format('YYYY-MM-DD HH:mm');
  };

  var linkFormatter = function linkFormatter(row, cell, value, columnDef, dataContext) {
    var link = dataContext.record.get(columnDef.id);

    if (link) {
      return "<a href=\"".concat(link, "\" target=\"_blank\">").concat(link, "</a>");
    } else {
      return null;
    }
  };

  var defaultColumns = [{
    id: "edit",
    field: "edit",
    name: "Edit",
    formatter: editItemFormatter,
    width: 75
  }, {
    id: "name",
    field: "name",
    name: "Name",
    width: 300,
    sortable: true
  }, {
    id: "kam",
    field: "kam.id",
    name: "KAM",
    width: 200,
    sortable: true
  }, {
    id: "baseUrl",
    field: "baseUrl",
    name: "Base Link",
    formatter: linkFormatter,
    width: 400,
    sortable: true
  }, {
    id: "sfAccountName",
    field: "sfAccountName",
    name: "SF Account",
    width: 200,
    sortable: true
  }, {
    id: "ssoProviders",
    field: "ssoProviders",
    name: "SSO Providers",
    width: 200,
    sortable: true
  }, {
    id: "createdAt",
    field: "createdAt",
    name: "Created date",
    formatter: dateFormatter,
    width: 150,
    sortable: true
  }, {
    id: "createdBy",
    field: "createdBy.id",
    name: "Created by",
    width: 200,
    sortable: true
  }, {
    id: "updatedAt",
    field: "updatedAt",
    name: "Last updated",
    formatter: dateFormatter,
    width: 150,
    sortable: true
  }, {
    id: "updatedBy",
    field: "updatedBy.id",
    name: "Updated by",
    width: 200,
    sortable: true
  }];

  var _default = Ember.Controller.extend({
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    cache: null,
    actions: {
      createNewCustomer: function createNewCustomer() {
        this.transitionToRoute('customers.customer', this.store.createRecord('customer'));
      },
      editItem: function editItem(id) {
        this.transitionToRoute('customers.customer', id);
      }
    },
    columnSettingsVersion: 1,
    columns: Ember.computed(function () {
      var storedColumns = this.get('settings.customerColumns');

      if (storedColumns && this.columnSettingsVersion == this.get('settings.customerColumnsVersion')) {
        // we need to overwrite the formatter here since usersettings cannot store functions 
        storedColumns.forEach(function (column) {
          var result = defaultColumns.findBy('id', column.id);

          if (result) {
            column.formatter = result.formatter;
          }
        });
        return storedColumns;
      } else {
        this.set('settings.customerColumns', null);
        this.set('settings.customerColumnsSortByCol', null);
        return defaultColumns;
      }
    })
  });

  _exports.default = _default;
});