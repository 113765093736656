define("mgw/templates/login-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FeIZA0Pd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex justify-center align-items-center height-100vh\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"spinner--global\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"three-quarters\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mgw/templates/login-loading.hbs"
    }
  });

  _exports.default = _default;
});