define("mgw/controllers/projects/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    projectsController: Ember.inject.controller('projects'),
    actions: {
      close: function close() {
        this.transitionToRoute('projects');
        this.set('projectsController.cache', new Date());
        return false;
      }
    }
  });

  _exports.default = _default;
});