define("mgw/models/workflow", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Workflow
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    forCreation: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    canEdit: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    editors: _emberData.default.hasMany('user', {
      async: true,
      inverse: null,
      defaultValue: []
    }),
    //copyWorkflow: DS.belongsTo('workflow'),
    workflowType: _emberData.default.attr('string', {
      defaultValue: 'workflow',
      readOnly: true
    }),
    // Preset values
    title: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    dueDate: _emberData.default.attr('date'),
    //assignedTo: DS.belongsTo('assignable', {async: true, inverse: null, polymorphic: true}),
    //labels: DS.hasMany('topicLabel', {async: true, inverse: null, defaultValue: []}),
    //priority: DS.belongsTo('topicPriority', {async: true, inverse: null}),
    // Access control

    /*generalAccess: DS.hasMany('group', {async: true, inverse: null}),
    canEditAssignedToAndDueDate: DS.hasMany('group', {async: true, inverse: null}),
    canBeAssigned: DS.hasMany('group', {async: true, inverse: null}),
    canSetCost: DS.hasMany('group', {async: true, inverse: null}),
    canSetPriority: DS.hasMany('group', {async: true, inverse: null}),
    canClose: DS.hasMany('group', {async: true, inverse: null}),
    canComment: DS.hasMany('group', {async: true, inverse: null}),*/
    // Read-only
    //creationAuthor: DS.belongsTo('user', {async: true, inverse: null, readOnly: true}),
    permissionKeys: ['generalAccess', 'canEditAssignedToAndDueDate', 'canBeAssigned', 'canClose', 'canSetPriority', 'canSetCost', 'canComment'],
    isWorkflowType: Ember.computed('workflowType', function () {
      return this.get('workflowType') === 'workflow';
    }),
    // Hard coded access for all workflows
    access: {
      title: {
        canEdit: true
      },
      labels: {
        canEdit: true
      },
      assignedTo: {
        canEdit: true
      },
      dueDate: {
        canEdit: true
      },
      description: {
        canEdit: true
      },
      priority: {
        canEdit: true
      },
      cost: {
        canEdit: true
      }
    }
  });

  _exports.default = _default;
});